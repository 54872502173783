import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header-styles.css";
import { VscThreeBars } from "react-icons/vsc";

const HeaderBars = ({ isLogin }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const logout = async () => {
    await localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="header-mini-container">
      <div className="header-mini-icon-background">
        <div className="header-mini-icon-container">
          {isLogin && (
            <VscThreeBars
              size={37}
              className="header-mini-icon"
              onClick={() => setShowDropdown(!showDropdown)}
            />
          )}
        </div>
      </div>
      {showDropdown && (
        <div className="header-dropdown-container">
          <Link
            to="/usersHome"
            className="header-dropdown-item"
            onClick={() => setShowDropdown(false)}
          >
            <p className="header-dropdown-text">Users</p>
          </Link>
          <Link
            to="/officesHome"
            className="header-dropdown-item"
            onClick={() => setShowDropdown(false)}
          >
            <p className="header-dropdown-text">Offices</p>
          </Link>

          <Link className="header-dropdown-item">
            <p className="header-dropdown-text" onClick={() => logout()}>
              Logout
            </p>
          </Link>
        </div>
      )}
    </div>
  );
};

export default HeaderBars;
