import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GoBack from "./components/utility/GoBack";
import Login from "./components/login/Login";
import UsersHome from "./components/usersHome/UsersHome";
import OfficesHome from "./components/officesHome/OfficesHome";
import HeaderContainer from "./components/header/HeaderContainer";
import CreateUser from "./components/createUser/CreateUser";
import Homepage from "./components/home/Homepage";
import AssignToOfficeContainer from "./components/assignUserToOffice/AssignToOfficeContainer";
import UpdateAssignmentContainer from "./components/updateAssigment/UpdateAssignmentContainer";
import ViewOfficeInfoContainer from "./components/viewOfficeInfo/ViewOfficeInfoContainer";
import ViewUserInfo from "./components/viewUserInfo/ViewUserInfo";
import EditUser from "./components/editUser/EditUser";
import SearchTwilio from "./components/officesHome/SearchTwilio";
import ResetPassword from "./components/userResetPassword/UserResetPassword";
import EditUserNumber from "./components/editUser/EditUserNumber";
import PrivacyPolicyContainer from "./components/privacyPolicy/PrivacyPolicyContainer";
import UserCommRecordsContainer from "./components/userCommRecords/UserCommRecordsContainer";
import OfficeCommRecordsContainer from "./components/officeCommRecords/OfficeCommRecordsContainer";
import ProtectIT from "./components/protectRoutes/ProtectIT";
import TextConversation from "./components/officeCommRecords/TextConversation";
import VerifyCallerId from "./components/verifyCallerId/verifyCallerId";
import TwillioOnboarding from "./components/twillioOnboarding/twillioOnboarding";
import TwillioOnboardingPublic from "./components/twillioOnboarding/twillioOnboardingPublic";
import AppOnboarding from "./components/appOnboaring/appOnboarding";

const App = () => {
  const email = JSON.parse(localStorage.getItem("email"));
  const authToken = JSON.parse(localStorage.getItem("authToken"));
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

  if (!email || !authToken || !refreshToken) {
    return (
      <div className="app">
        <Router>
          <HeaderContainer isLogin={false} />
          <div className="main-content-container">
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route
                path="/privacyPolicy"
                element={<PrivacyPolicyContainer />}
              />
              <Route
                path="/trusthuboffice"
                element={<TwillioOnboardingPublic />}
              />
              <Route path="/app" element={<AppOnboarding />} />
            </Routes>
          </div>
        </Router>
      </div>
    );
  } else {
    return (
      <div className="app">
        <Router>
          <HeaderContainer isLogin={true} />
          <div className="main-content-container">
            <GoBack />
            <Routes>
              <Route path="/*" element={<Homepage />} />
              <Route path="/usersHome" element={<UsersHome />} />
              <Route
                path="/createUser"
                element={
                  <ProtectIT>
                    {" "}
                    <CreateUser />{" "}
                  </ProtectIT>
                }
              />
              <Route path="/officesHome" element={<OfficesHome />} />
              <Route
                path="/assignUserToOffice"
                element={
                  <ProtectIT>
                    <AssignToOfficeContainer />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/userCommRecords"
                element={<UserCommRecordsContainer />}
              />
              <Route
                path="/officeCommRecords"
                element={<OfficeCommRecordsContainer />}
              />
              <Route
                path="/updateAssigment/:officeSysName/:officeId/:userId"
                element={
                  <ProtectIT>
                    {" "}
                    <UpdateAssignmentContainer />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/viewOfficeInfo"
                element={<ViewOfficeInfoContainer />}
              />
              <Route path="/viewUserInfo" element={<ViewUserInfo />} />
              <Route
                path="/editUser/:userId/:userEmail/:userFirstName/:userLastName"
                element={
                  <ProtectIT>
                    {" "}
                    <EditUser />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/searchTwilio/:officeId/:officeSysName/:officePhoneNumber"
                element={
                  <ProtectIT>
                    {" "}
                    <SearchTwilio />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/searchTwilio/:officeId/:officeSysName/:officePhoneNumber/:twilioNumber"
                element={
                  <ProtectIT>
                    {" "}
                    <SearchTwilio />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/textConversation/:id/:customerNumber/:officeId"
                element={<TextConversation />}
              />

              <Route path="/userResetPassword" element={<ResetPassword />} />
              <Route
                path="/editUserNumber/:userId/:userEmail"
                element={
                  <ProtectIT>
                    {" "}
                    <EditUserNumber />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/verifyCallerId/:officeId/:officeSysName"
                element={
                  <ProtectIT>
                    {" "}
                    <VerifyCallerId />{" "}
                  </ProtectIT>
                }
              />
              <Route
                path="/twillioOnboarding/:officeId"
                element={
                  <ProtectIT>
                    <TwillioOnboarding />
                  </ProtectIT>
                }
              />
              <Route
                path="/privacyPolicy"
                element={<PrivacyPolicyContainer />}
              />
            </Routes>
          </div>
        </Router>
      </div>
    );
  }
};

export default App;
