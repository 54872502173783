import { post, callDelete } from "../util/utilFunc";

export const verify = async (phoneNumber) => {
  try {
    const res = await post(
      `/office/callerId/verify`,
      {},
      {
        phoneNumber: phoneNumber,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const addOfficeNumber = async (phoneNumber, officeId) => {
  try {
    const res = await post(
      `/office/callerId/${officeId}`,
      {},
      {
        phoneNumber: phoneNumber,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const deactivateCallerId = async (officeId) => {
  try {
    const res = await callDelete(`/office/callerId/${officeId}`, {}, {});
    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};
