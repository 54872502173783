import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatPhoneNumber,
  formatTime,
  secondsToMinutes,
  formatType,
  backgroundColor,
} from "../../functions/util/format";
import { BsPlayFill, BsStopFill, BsCloudArrowDown } from "react-icons/bs";
import { MdArchive, MdUnarchive } from "react-icons/md";
import Sound from "react-sound";
import serverUrl from "../../constants/serverUrl";
import ArchiveCommModal from "../modals/ArchiveCommModal";
import fileDownload from "js-file-download";

const Recording = ({ recording, reload }) => {
  //the react-sound pkg prints authToken to console without the debugMode turned off.
  //aka keep this line it is very important
  window.soundManager.setup({ debugMode: false });
  /////
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTo, setFormattedTo] = useState("");
  const [formattedType, setFormattedType] = useState("");
  const [formattedFrom, setFormattedFrom] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [formattedDuration, setFormattedDuration] = useState("");
  const [color, setColor] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordingLink, setRecordingLink] = useState("");
  const [archiveCommModal, setArchiveCommModal] = useState(false);

  useEffect(() => {
    const formatting = async () => {
      const color = await backgroundColor(recording.flag, recording.type);
      setColor(color);
      const date = await formatDate(recording.recordingStart);
      setFormattedDate(date);
      const fromNumber = await formatPhoneNumber(recording.from);
      setFormattedFrom(fromNumber);
      const toNumber = await formatPhoneNumber(recording.to);
      setFormattedTo(toNumber);
      const time = await formatTime(recording.recordingStart);
      setFormattedTime(time);
      const vmDuration = await secondsToMinutes(recording.recordingDuration);
      setFormattedDuration(vmDuration);
      const rawType = await formatType(recording.type);
      setFormattedType(rawType);
    };
    formatting();
  }, [recording.flagged, color]);

  const handlePlay = async (serverUrl) => {
    setIsPlaying(!isPlaying);
    const twilioUrl = await encodeURI(recording.recordingUrl);
    let authToken = await localStorage.getItem("authToken");
    // removes quotations from authToken string
    authToken = authToken.replace(/"/g, "");
    const recordingLink =
      serverUrl + "/recording?twilioUrl=" + twilioUrl + "&token=" + authToken;
    setRecordingLink(recordingLink);
  };

  const handleDownload = async () => {
    const twilioUrl = await encodeURI(recording.recordingUrl);
    let authToken = await localStorage.getItem("authToken");
    // removes quotations from authToken string
    authToken = authToken.replace(/"/g, "");
    const recordingLink =
      serverUrl + "/recording?twilioUrl=" + twilioUrl + "&token=" + authToken;

    fetch(recordingLink)
      .then((response) => response.blob())
      .then((blobData) => {
        // Use fileDownload to save the blob data
        fileDownload(
          blobData,
          `${formattedDate} ${recording.from}  ${recording.to} recording.wav`
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <tr style={{ backgroundColor: color }}>
      <td className="table-item">{formattedDate}</td>
      <td className="table-item">{formattedTime}</td>
      <td className="table-item">{formattedType}</td>
      <td className="table-item">{formattedFrom}</td>
      <td className="table-item">{formattedTo}</td>
      <td className="table-item">{formattedDuration}</td>
      <td className="table-icon-container">
        {isPlaying ? (
          <BsStopFill
            className="table-icon"
            color="#219ebc"
            size={35}
            onClick={() => setIsPlaying(false)}
          />
        ) : (
          <BsPlayFill
            className="table-icon"
            color="#219ebc"
            size={35}
            onClick={() => handlePlay(serverUrl)}
          />
        )}
        <Sound
          url={recordingLink}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
          onFinishedPlaying={() => setIsPlaying(false)}
        />
        <BsCloudArrowDown
          className="table-icon"
          color="#000"
          size={25}
          onClick={handleDownload}
        />
      </td>
      <td className="table-icon-container">
        {recording.isArchived === true ? (
          <MdUnarchive
            className="table-icon"
            color="#adadad"
            size={35}
            onClick={() => setArchiveCommModal(true)}
            title="Unarchive"
          ></MdUnarchive>
        ) : (
          <MdArchive
            className="table-icon"
            color="#fb8500"
            size={35}
            onClick={() => setArchiveCommModal(true)}
            title="Archive"
          />
        )}
        {archiveCommModal && (
          <ArchiveCommModal
            setArchiveCommModal={setArchiveCommModal}
            isArchived={recording.isArchived}
            voicemailId={recording._id}
            reload={reload}
          />
        )}
      </td>
    </tr>
  );
};

export default Recording;
