import React from 'react'

const Paragraph = ({ content, header }) => {

    const styles = {
        fontWeight: header ? "bold" : "normal",
        display: "flex",
        justifyContent: "flex-start"
    }

    return (
        <p style={styles}>
            {content}
        </p>
    )
}

export default Paragraph