import React, { useState } from "react";
import { finishVerify } from "../../functions/login/loginFunc";

const Verify = ({ userInfo }) => {
  //Comes from user input
  const [userCode, setUserCode] = useState("");

  //Called when user presses verify. Calls finishVerify function which returns a boolean
  //if finishVerify returns true then loginRes is called and loginStatus is set to the token
  const handleVerify = async () => {
    const success = await finishVerify(userCode, userInfo);
    if (success) {
      window.location.reload();
    } else {
      alert("Verification code was incorrect.");
    }
  };

  return (
    <div className="form-error-message-container">
      <p className="form-error-message-text">
        Enter the verification code sent to your phone
      </p>
      <input
        placeholder="Verification Code"
        className="form-input"
        style={{ maxWidth: "60%", margin: "0.3em", fontWeight: "normal" }}
        onChange={(e) => setUserCode(e.target.value)}
      />
      <button className="form-btn" onClick={() => handleVerify()}>
        Verify
      </button>
    </div>
  );
};

export default Verify;
