import React, { useState, useEffect } from "react";
import NoAuthIT from "./NoAuthIT";

const ProtectIT = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const getAuthStatus = async () => {
      try {
        const accessLevel = localStorage.getItem("access");

        if (parseInt(accessLevel) === 3) {
          setAuthorized(true);
        } else {
          setAuthorized(false);
        }
      } catch (error) {
        console.log(error);
        alert("There was an error that prevented you from being authorized");
      }
    };

    getAuthStatus();
  }, []);

  if (!authorized) return <NoAuthIT />;

  return children;
};

export default ProtectIT;
