import React from "react";

const TextHelpModal = ({ setTextHelpModal }) => {
  return (
    <>
      <div className="modal-overlay" onClick={() => setTextHelpModal(false)} />
      <div className="modal-wrapper">
        <div className="modal" style={{ height: "300px" }}>
          <div className="modal-header">
            <h5 className="modal-heading">Help</h5>
          </div>
          <div className="modal-message" style={{ textAlign: "left" }}>
            <ul>
              <li>
                The message timestamp is shown in the browser's local time.{" "}
              </li>
              <br />
              <li>
                The most recent message is displayed at bottom, scroll up to see
                older messages.
              </li>
            </ul>
          </div>
          <div className="modal-btns">
            <button
              className="modal-cancel-btn"
              onClick={() => setTextHelpModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TextHelpModal;
