import React, { useState } from "react";

import ResultBox from "../utility/ResultBox";
import { sendPasswordReset } from "../../functions/user/sendPasswordReset";

const ResetPassword = () => {
  const [email, setEmail] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [messageActive, setMessageActive] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetMessages = () => {
    setMessageActive(false);
    setResultMessage(null);
    setShowResult(false);
    setShowConfirm(false);
    setSuccess(false);
  };

  const sendReset = async () => {
    setShowResult(false);

    const res = await sendPasswordReset(email);

    if (res) {
      setResultMessage("Success: Password reset email sent");
      setSuccess(true);
      setShowResult(true);
    } else {
      setResultMessage("Error: Failed to send password reset");
      setSuccess(false);
      setShowResult(true);
    }
  };

  const checkInputs = async () => {
    setMessageActive(false);
    setResultMessage(null);

    if (!email) {
      setMessageActive(true);
      setResultMessage("Missing information: please fill out all inputs");
    } else setShowConfirm(true);
  };

  return (
    <div className="form-wrapper">
      <div className="form-container">
        <h3 className="form-title">Send Password Reset Email</h3>
        <p className="form-item">User Email</p>
        <input
          className="form-input"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          placeholder="Only eassist.me emails"
        />
        <br />

        {messageActive ? (
          <>
            <h4 className="form-error-message-text">{resultMessage}</h4>
            <button className="form-btn" onClick={() => resetMessages()}>
              Remove Message
            </button>
          </>
        ) : null}
        {showConfirm ? (
          <>
            <p className="form-error-message-text">
              Are you sure you want to send?
            </p>
            <div className="form-btn-container">
              <button className="form-btn" onClick={() => sendReset()}>
                Send
              </button>
              <button className="form-btn" onClick={resetMessages}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <button className="form-btn" onClick={checkInputs}>
            Send
          </button>
        )}
        {showResult && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ResultBox message={resultMessage} success={success} />
            </div>
            <button className="form-btn" onClick={resetMessages}>
              Reset
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
