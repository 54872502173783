import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./users-home-styling.css";

const UsersHome = () => {
  const [access, setAccess] = useState("");

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("access");
        setAccess(token);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);

  return (
    <div className="users-home-wrapper">
      <h2 className="users-home-title">User Management</h2>
      <div className="users-home-btns-wrapper">
        {parseInt(access) === 3 ? (
          <>
            <Link to="/createUser" className="users-home-btn">
              <p className="users-home-btn-text">Create User</p>
            </Link>
            <Link to="/viewUserInfo" className="users-home-btn">
              <p className="users-home-btn-text">View User</p>
            </Link>
            <Link to="/userResetPassword" className="users-home-btn">
              <p className="users-home-btn-text">Password Reset</p>
            </Link>{" "}
          </>
        ) : null}
        <Link to="/userCommRecords" className="users-home-btn">
          <p className="users-home-btn-text">User Communication Records</p>
        </Link>
      </div>
    </div>
  );
};

export default UsersHome;
