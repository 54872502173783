import { callDelete, put } from "../util/utilFunc";

export const deleteUser = async (userId) => {
  try {
    const res = await callDelete(`/user/${userId}`, {}, {});

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateUser = async (
  userId,
  firstName,
  lastName,
  email,
  access,
  active
) => {
  try {
    let body = {};

    if (firstName !== "") body.firstName = firstName;
    if (lastName !== "") body.lastName = lastName;
    if (email !== "") body.email = email;
    if (access !== null) body.access = access;
    if (active !== null) body.active = active;

    const res = await put(`/user/${userId}`, {}, body);

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const reactivateUser = async (userId) => {
  try {
    const res = await put(`/user/reactivate/${userId}`, {}, {});

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};