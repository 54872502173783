import React from "react";

const NoAuthIT = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "3em",
        color: "#023047",
      }}
    >
      <h3>Not Authorized</h3>
      <p>You must have IT access in order to use this page.</p>
      <p>
        If you should have IT access and are seeing this message please contact
        IT to get this fixed.
      </p>
    </div>
  );
};

export default NoAuthIT;
