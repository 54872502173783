import { callDelete, get, put } from "../util/utilFunc";

export const searchOffices = async (search) => {
  try {
    const res = await get("/office", { search });
    if (res.result === 0) {
      return res.offices;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const deleteOffice = async (officeId) => {
  try {
    const res = await callDelete(`/office/${officeId}`, {}, {});

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const fetchOfficeInfo = async (officeId) => {
  try {
    const res = await get(`/office/${officeId}`, {});

    if (res.result === 0) {
      return res.office;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateOffice = async (officeId, officeName) => {
  try {
    const res = await put(
      `/office/${officeId}`,
      {},
      {
        officeName,
      }
    );

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
