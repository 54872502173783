import React, { useState } from "react";
import OfficesDropdown from "../dropdowns/OfficesDropdown";
import UserDropdown from "../dropdowns/UserDropdown";
import "./assign-user-to-office-styling.css";
import MakeAssignmentModal from "../modals/MakeAssignmentModal";

const AssignToOfficeContainer = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedOffice, setSelectedOffice] = useState("");
  const [dbChecked, setDbChecked] = useState(false);
  const [pbChecked, setPbChecked] = useState(false);
  const [fsChecked, setFsChecked] = useState(false);
  const [makeAssignmentModal, setMakeAssignmentModal] = useState(false);

  const handleUserChange = (userId) => {
    setSelectedUser(userId);
  };

  const handleOfficeChange = (officeId) => {
    setSelectedOffice(officeId);
  };

  return (
    <div className="assign-wrapper">
      <h3 className="assign-title">Assign a User to an Office</h3>
      <div className="assign-cards-wrapper">
        <div className="assign-half-container">
          <div className="assign-card-content-container">
            <p className="assign-title">Select a User</p>
            <div style={{ width: "80%" }}>
              <UserDropdown handleChange={handleUserChange} />
            </div>
          </div>
          <div className="assign-card-content-container">
            <p className="assign-title">Select an Office</p>
            <div style={{ width: "80%" }}>
              <OfficesDropdown handleChange={handleOfficeChange} />
            </div>
          </div>
        </div>

        <div className="assign-half-container">
          <div className="assign-card-content-container">
            <p className="assign-title">Select User Access Level</p>
            <div className="assign-checkbox-container">
              <label className="assign-checkbox-label">
                <input
                  type="checkbox"
                  checked={dbChecked}
                  onChange={() => setDbChecked(!dbChecked)}
                  className="assign-checkbox"
                />
                Dental Billing
              </label>
              <label className="assign-checkbox-label">
                <input
                  type="checkbox"
                  checked={pbChecked}
                  onChange={() => setPbChecked(!pbChecked)}
                  className="assign-checkbox"
                />
                Patient Billing
              </label>
              <label className="assign-checkbox-label">
                <input
                  type="checkbox"
                  checked={fsChecked}
                  onChange={() => setFsChecked(!fsChecked)}
                  className="assign-checkbox"
                />
                Full Schedule
              </label>
            </div>
            <div className="assign-btns">
              <button
                className="assign-btn"
                onClick={() => setMakeAssignmentModal(true)}
              >
                Make Assignment
              </button>
              {makeAssignmentModal && (
                <MakeAssignmentModal
                  setMakeAssignmentModal={setMakeAssignmentModal}
                  userId={selectedUser}
                  officeId={selectedOffice}
                  dbChecked={dbChecked}
                  pbChecked={pbChecked}
                  fsChecked={fsChecked}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignToOfficeContainer;
