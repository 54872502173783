import { put } from "../util/utilFunc";

export const editNumber = async (phoneNumber, id) => {
    try {
        const res = await put(`/user/phoneNumber/${id}`, {}, {
            phoneNumber: phoneNumber,
        });

        return res;
    } catch (error) { 
        console.log(error);
        return { result: 1 };
    }
};