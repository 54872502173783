import React, { useEffect, useState } from "react";
import { formatDate, formatTime } from "../../functions/util/format";

const TextConvoDatetime = (createdAt) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const formatting = async () => {
      const datetime = createdAt.createdAt;
      const date = await formatDate(datetime);
      setFormattedDate(date);
      const time = await formatTime(datetime);
      setFormattedTime(time);
    };
    formatting();
  }, []);

  return (
    <>
      {formattedDate} {formattedTime}
    </>
  );
};

export default TextConvoDatetime;
