import { callDelete, get, post, put } from "../util/utilFunc";

export const addOfficeToUser = async (officeId, userObject) => {
  try {
    const assignmentArray = [userObject];

    const res = await post(
      `/office/users/${officeId}`,
      {},
      {
        assignmentArray,
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const removeOfficeFromUser = async (officeId, userId) => {
  try {
    const userIdArray = [userId];

    const res = await callDelete(
      `/office/users/${officeId}`,
      {},
      {
        userIdArray,
      }
    );

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateUserAssignment = async (
  officeId,
  userId,
  dbService,
  pbService,
  fsService
) => {
  try {
    const res = await put(
      `/office/users/${officeId}`,
      {},
      {
        userId,
        dbService,
        pbService,
        fsService,
      }
    );

    if (res.result === 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const fetchOfficeUsers = async (officeId) => {
  try {
    const res = await get(`/office/users/${officeId}`);

    if (res.result === 0) {
      return res.users;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
