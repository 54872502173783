import React, { useState } from "react";
import { archiveRecording } from "../../functions/office/commRecords";
import { useNavigate } from "react-router";

const ArchiveCommModal = ({
  setArchiveCommModal,
  isArchived,
  voicemailId,
  reload,
}) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(
    `Are you sure you want ${
      isArchived ? "Restore" : "Archive"
    } this voicemail?`
  );
  const [showConfirmBtn, setShowConfirmBtn] = useState(true);

  const handleClose = () => {
    navigate(`/officeCommRecords`);
    reload();
    setArchiveCommModal(false);
  };

  const confirmed = async () => {
    const result = await archiveRecording(voicemailId);

    if (result) {
      setMessage(`Success: Voicemail ${isArchived ? "Restored" : "Archived"}.`);
      setShowConfirmBtn(false);
    } else {
      setMessage("Error: Failed to archive voicemail.");
      setShowConfirmBtn(false);
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={() => handleClose()} />
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h5 className="modal-heading">Confirmation</h5>
          </div>
          <div className="modal-message">{message}</div>
          <div className="modal-btns">
            {showConfirmBtn && (
              <button className="modal-confirm-btn" onClick={() => confirmed()}>
                Confirm
              </button>
            )}
            <button className="modal-cancel-btn" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveCommModal;
