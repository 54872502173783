import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveTab } from "../../redux/slices/headerSlice";
import "./header-styles.css";

const HeaderLink = ({ title, to }) => {
  const activeTab = useSelector(selectActiveTab);
  const isActive = title === activeTab ? true : false;

  const logout = async () => {
    await localStorage.clear();
    window.location.reload();
  };

  if (title === "Logout") {
    return (
      <div className="header-link-inactive" onClick={() => logout()}>
        <p className="header-link-text">{title}</p>
      </div>
    );
  }

  return (
    <Link
      to={to}
      className={isActive ? "header-link-active" : "header-link-inactive"}
    >
      <p className="header-link-text">{title}</p>
    </Link>
  );
};

export default HeaderLink;
