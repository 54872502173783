import React, { useEffect, useState } from "react";
import {
  formatDate,
  formatPhoneNumber,
  formatTime,
  secondsToMinutes,
  backgroundColor,
  formatDateTime,
} from "../../functions/util/format";

const Logs = ({ reload, call }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTo, setFormattedTo] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [formattedDuration, setFormattedDuration] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    const formatting = async () => {
      const color = await backgroundColor("false", call.type);
      setColor(color);
      const date = await formatDate(call.dateCreated);
      setFormattedDate(date);
      const toNumber = await formatPhoneNumber(call.to);
      setFormattedTo(toNumber);
      const time = await formatDateTime(call.startTime);
      setFormattedTime(time);
      const vmDuration = await secondsToMinutes(call.duration);
      setFormattedDuration(vmDuration);
    };
    formatting();
  }, []);

  return (
    <tr style={{ backgroundColor: color }}>
      <td className="table-item">{formattedDate}</td>
      <td className="table-item">{formattedTime}</td>
      <td className="table-item">{call.direction}</td>
      <td className="table-item">{formattedTo}</td>
      <td className="table-item">{formattedDuration}</td>
      {/* <td className="table-icon-container">$ {call.price}</td> */}
      <td className="table-icon-container">{call.status}</td>
    </tr>
  );
};

export default Logs;
