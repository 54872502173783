import { get } from "../util/utilFunc";

export const getAllUsers = async (type) => {

    let res;

    if (type === "activated") res = await get('/user/activated', {});
    else if (type === "deactivated") res = await get('/user/deactivated', {});
    else res = await get('/user', {});

    if (res) {
        if (res.result === 0) {
            return res.users;
        }
    }
}; 

export const fetchSingleUser = async (userId) => {
    try {
        const res = await get(`/user/${userId}`);

        if (res.result === 0) {
            return  res.user;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getOfficesAssignedToUser = async (userId) => {
    const res = await get(`/user/offices/${userId}`, {});
    if (res) {
        if (res.result === 0) {
            return res.offices
        }
    }
};