import React from "react";
import "./comm-records-styling.css";
import TextConversationBtns from "./TextConversationBtns";

const TextConversationsContainer = ({ conversations }) => {
  return (
    <div className="comm-text-records-wrapper">
      <div className="comm-convo-btns-container">
        <div className="comm-text-headings-container">
          <div
            className="comm-convos-heading-font"
            style={{ alignSelf: "flex-end" }}
          >
            Number
          </div>
          <div className="comm-convos-heading-font">Date</div>
        </div>
        <div className="comm-scrollable" style={{ flexDirection: "column" }}>
          {conversations.length > 0 ? (
            <>
              {conversations.map((conversation) => (
                <TextConversationBtns
                  key={conversation._id}
                  _id={conversation._id}
                  customerNumber={conversation.customerNumber}
                  officeId={conversation.officeId}
                  convoDate={conversation.createdAt}
                />
              ))}
            </>
          ) : (
            <p
              className="comm-convos-heading-font"
              style={{ textAlign: "center" }}
            >
              No conversations to display.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextConversationsContainer;
