import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import appScreenShot from "../../images/app.png";
import video from "../../assets/videos/android_install.mp4";
import Loading from "../loading/Loading";
import { post } from "../../functions/util/utilFunc";

const AppOnboarding = () => {
  const navigate = useNavigate();
  const getStarted = React.createRef();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    platform: "Android",
    agree: false,
    agreeAndroid: false,
  });

  const platforms = [
    {
      value: "Android",
      label: "Android",
    },
    {
      value: "IOS",
      label: "IOS",
    },
  ];

  const [submitted, setSubmitted] = useState(false);
  const [androidInst, setAndroidInst] = useState(false);

  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await post("/appOnboarding", {}, formData);
    setLoading(false);
    if (res.message && res.message === "OK") {
      if (formData.platform === "Android") {
        setAndroidInst(true);
      } else {
        setSubmitted(true);
      }
      //   setSubmitted(true);
    } else {
      alert(res.message);
    }
  };

  const completeAndroid = () => {
    setAndroidInst(false);
    setSubmitted(true);
  };

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setFormData({
        ...formData,
        platform: "Android",
      });
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setFormData({
        ...formData,
        platform: "IOS",
      });
    }
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!submitted && !loading && (
        <div className="w-full h-full">
          {!androidInst && (
            <div className="grid md:grid-cols-2  mb-5">
              <div className="bg-blue-200 items-center justify-center flex shadow-lg py-10">
                <div className="px-10 lg:w-3/4">
                  <h1 className="font-semibold text-3xl text-left mb-8 text-gray-700">
                    Welcome to Zilla App <br />
                    <strong className="font-bold text-5xl text-black">
                      by eAssist
                    </strong>
                  </h1>

                  <p className="text-lg mb-10 text-gray-800">
                    Zilla, developed by the eAssist Applications Team, empowers
                    Success Consultants to communicate with patients and
                    insurance companies regardless of their location, while
                    legally masking their office identification.
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      scroll(getStarted);
                    }}
                    className="bg-blue-500 text-white py-3 px-8 font-bold rounded hover:shadow-lg"
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <div className="bg-blue-100 flex items-center justify-center shadow-lg relative py-5  order-first md:order-last">
                <div className="w-5/12 z-20">
                  <img src={appScreenShot} alt="app" className="w-full" />
                </div>
                {/* <div className="absolute w-6/12 h-3/6 bg-gray-800/10 rounded-full z-0 shadow-xl"></div>
          <div className="absolute w-8/12 h-4/6 bg-gray-400/20 rounded-full z-0 shadow-xl"></div> */}
              </div>
            </div>
          )}

          {!androidInst && (
            <div className=" h-full w-full pt-10 px-2" ref={getStarted}>
              <div className="w-auto bg-gray-50 py-10 px-4 mx-auto md:w-8/12 rounded shadow-lg border">
                <p className="text-center font-bold text-xl">
                  Get Started with Zilla
                </p>
                <p className="text-center mb-10">
                  Provide your eAssist email to subscribe to Zilla for Android
                  devices
                </p>

                <form name="trustHubForm" onSubmit={submitForm}>
                  <div className="grid md:grid-cols-2 gap-4 w-full mb-5">
                    <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        eAssist Email
                      </p>
                      <input
                        type="email"
                        className="w-full p-2 py-2 rounded shadow"
                        placeholder="john.doe@eassist.me"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    {/* <div>
                      <p className="text-sm text-gray-600 font-semibold">
                        Device Platform
                      </p>
                      <Select
                        className="w-full rounded shadow"
                        options={platforms}
                        value={platforms.filter(
                          (e) => e.value === formData.platform
                        )}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            platform: e.value,
                          })
                        }
                        placeholder={"Select Device Platform"}
                        required={true}
                      />
                    </div> */}
                  </div>
                  <p className="text-sm text-gray-600 font-semibold">
                    Terms & Conditions
                  </p>
                  <div className="bg-white h-80 overflow-auto py-3 px-4 mb-5">
                    <h1 dir="ltr">
                      <span>
                        <strong>Zilla App - Terms and Conditions</strong>
                      </span>
                    </h1>
                    <p dir="ltr">
                      <span>
                        By accessing or using the Zilla App, you agree to comply
                        with and be bound by these Terms. If you do not agree to
                        these Terms, please do not use or install the App.
                      </span>
                    </p>
                    <p>
                      <span>
                        <span>&nbsp;</span>
                      </span>
                    </p>
                    <p dir="ltr">
                      <span>Last Updated: 3/5/2024</span>
                    </p>
                    <h3 dir="ltr">
                      <span className="font-bold">1. Definitions</span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        1.1 Zilla App: Refers to the mobile application provided
                        by eAssist for Success Consultants to communicate with
                        insurance companies and patients.
                      </span>
                    </p>
                    <p>
                      1.2 Autobots Team: The development team working on the
                      continuous improvement of the Zilla App.
                    </p>
                    <p>
                      1.3 Beta Version: An early version of the Zilla App that
                      is still in the development phase.
                    </p>
                    <p>1.4 Twilio: The Zilla communication provider.</p>
                    <p dir="ltr">&nbsp;</p>
                    <h3 dir="ltr">
                      <span className="font-bold">2. Services</span>
                    </h3>
                    <p>2.1 Dental Billing and Insurance Verification:</p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Users use a local phone number for outgoing calls or
                            mask the main office number if approved by the
                            client.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Exclusively for making outbound calls, no voicemail,
                            incoming calls, or text features.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            All calls are recorded and users need to let
                            insurance representatives know.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Recorded calls stored in the internal Zilla
                            database.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p>
                      <span>
                        <span>&nbsp;</span>
                      </span>
                    </p>
                    <p dir="ltr">
                      <span>2.2 Patient Billing:</span>
                    </p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Outbound calls masking the practice name; all
                            inbound calls go directly to voicemail.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Users get the ability to personalize voicemail
                            greetings for each office.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>No texting feature.</span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            All calls are recorded, and an automated
                            notification is sent upon connection for legal
                            compliance.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Recorded calls stored in the internal Zilla
                            database.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <h3 dir="ltr">&nbsp;</h3>
                    <h3 dir="ltr">
                      <span className="font-bold">3. Fees&nbsp;</span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        Zilla does not incur any additional charges for offices
                        already utilizing eAssist services.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <h3 dir="ltr">
                      <span className="font-bold">
                        4. Ownership, Customer Data, and Confidentiality
                      </span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        4.1 Users retain ownership of the content they transmit
                        through the App.
                      </span>
                    </p>
                    <p>
                      4.2 Users grant Zilla a non-exclusive, royalty-free
                      license to use, reproduce, and distribute their content
                      for the purpose of providing services.
                    </p>
                    <p>
                      4.3 Zilla respects the confidentiality of user data and
                      complies with applicable privacy laws.
                    </p>
                    <p>
                      4.4 Only eAssist team members and IT department can access
                      Zilla phone numbers.
                    </p>
                    <p>
                      4.5 Access is quickly revoked for team members removed
                      from the office team.
                    </p>
                    <p>
                      4.6 A secure Zilla web portal is available for Team
                      Leaders for reporting and quality assurance purposes only.
                    </p>
                    <h3 dir="ltr">&nbsp;</h3>
                    <h3 dir="ltr">
                      <span className="font-bold">5. General Use</span>
                    </h3>
                    <p dir="ltr">
                      <span>5.1 User Account</span>
                    </p>
                    <p>
                      5.1.1 eAssist Email: Users must use their eAssist email
                      for account creation.
                    </p>
                    <p>
                      5.1.2 Confidentiality: Users are responsible for
                      maintaining the confidentiality of their account
                      credentials.
                    </p>
                    <p>
                      <span>5.2 </span>
                      <span>Android Device Requirements and </span>
                      <a href="https://drive.google.com/file/d/1PYiL33bv8toCQt6mxpGvzpvG2ZQHuiEW/view?usp=sharing">
                        <span>Work Profile</span>
                      </a>
                    </p>
                    <p>
                      5.2.0 Android OS version: Android OS version 9 or higher
                      is required.
                    </p>
                    <p>5.2.1 Work Profile on Android - Only for Zilla Users:</p>
                    <p dir="ltr">
                      <span>
                        By adding your eAssist Gmail account to your Android as
                        a Zilla User, you agree to:
                      </span>
                    </p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>Create a work profile</span>
                          <span>
                            : Opening your eAssist Gmail account will prompt the
                            creation of a work profile on your device to keep
                            personal and work data separate.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Work apps and data being managed by IT. They will be
                            kept separate from your personal apps and data,
                            which will remain private from IT.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p>5.2.2 Data Sync and App Installation:</p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Users may sync personal and work schedules within
                            the Android Work Profile.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Reinstallation of essential Google apps for work
                            within the work profile is needed.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p dir="ltr">
                      <span>5.2.3 Completion Verification:</span>
                    </p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Appearance of two new tabs, "Personal" and "Work,"
                            confirms successful setup and new mobile App and
                            Data separation.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <p>5.3 iOS Device Requirements</p>
                    <p>
                      5.3.1 Downloading the app via TestFlight on iOS devices is
                      required.
                    </p>
                    <p>
                      5.3.2 iOS version: iOS version 14 or higher is required.
                    </p>
                    <p>5.4 Service Usage</p>
                    <p dir="ltr">
                      <span>
                        5.4.1 The App provides VOIP communication services,
                        subject to applicable laws.
                      </span>
                    </p>
                    <p>
                      5.4.2 Users agree not to use the App for any illegal or
                      unauthorized purpose.
                    </p>
                    <p dir="ltr">
                      <span>5.5 Legal Consent</span>
                    </p>
                    <p>
                      <span>
                        <span>&nbsp;</span>
                      </span>
                    </p>
                    <ul>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            To display the practice name on outbound calls,
                            offices will be required to complete a form attached
                            on the Zilla Brochure.&nbsp;
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            Twilio will reach out within 72 up to a month to
                            confirm business consent.
                          </span>
                        </p>
                      </li>
                      <li dir="ltr">
                        <p dir="ltr">
                          <span>
                            To mask the office number, the eAssist Help Desk
                            team will reach out to the office to complete
                            verification steps by phone call.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <h2 dir="ltr">&nbsp;</h2>
                    <h3 dir="ltr">
                      <span className="font-bold">6. Termination</span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        6.1 Zilla reserves the right to suspend or terminate
                        user accounts for violations of these terms.
                      </span>
                    </p>
                    <p>
                      6.2 Upon termination, all rights granted to users will
                      cease immediately.
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <h3 dir="ltr">
                      <span className="font-bold">
                        7. Limitation of Liability
                      </span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        7.1 Zilla is not liable for direct, indirect,
                        incidental, or consequential damages.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span>
                        7.2 In no event shall Zilla's liability exceed the
                        amount paid by users for the services.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <h3 dir="ltr">
                      <span className="font-bold">8. Changes to Terms</span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        8.1 Zilla reserves the right to modify these terms at
                        any time.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span>
                        8.2 Users will be notified of changes, and continued use
                        constitutes acceptance.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <h3 dir="ltr">
                      <span className="font-bold">9. Contact Information</span>
                    </h3>
                    <p dir="ltr">
                      <span>
                        If you have any questions or concerns about these terms,
                        please contact us at autobots@eassist.me.
                      </span>
                    </p>
                  </div>

                  <div class="flex items-center mb-4 ml-2">
                    <input
                      id="checkbox"
                      name="checkbox1"
                      type="checkbox"
                      required
                      defaultChecked={formData.agree}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          agree: !formData.agree,
                        });
                      }}
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 cursor-pointer"
                    />
                    <label
                      for="checkbox"
                      class="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
                    >
                      I agree with Zilla app Terms & Conditions
                    </label>
                  </div>

                  {formData.platform === "Android" && (
                    <div class="flex items-center mb-10 ml-2">
                      <input
                        id="android"
                        type="checkbox"
                        name="checkbox2"
                        required
                        defaultChecked={formData.agreeAndroid}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            agreeAndroid: !formData.agreeAndroid,
                          });
                        }}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 cursor-pointer"
                      />
                      <label
                        for="android"
                        class="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
                      >
                        I acknowledge that I'll need to set up a work profile as
                        an Android user.
                      </label>
                    </div>
                  )}

                  <div className="w-full flex justify-center">
                    <button
                      className="px-5 py-3 bg-blue-600 text-white font-semibold text-sm rounded hover:shadow-lg hover:bg-blue-700 disabled:bg-gray-400 disabled:shadow-none"
                      disabled={
                        !formData.agree ||
                        (formData.platform === "Android" &&
                          !formData.agreeAndroid)
                      }
                      type="submit"
                    >
                      Submit Zilla App Download Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {androidInst && (
            <div className=" h-full w-full pt-10 px-2" ref={getStarted}>
              <div className="w-auto bg-gray-50 py-10 px-8 mx-auto md:w-8/12 rounded shadow-lg border">
                <div className="mb-5">
                  <video src={video} controls={true} />
                </div>

                <p className="text-center font-black text-2xl mb-3">
                  Zilla For Android
                </p>
                <p className="text-center mb-10">
                  Zilla for Android requires setting up a{" "}
                  <span className="text-black font-bold">
                    Work Profile on your mobile
                  </span>{" "}
                  linked to your eAssist email. This keeps work apps and data
                  managed by IT separate from your personal information,
                  ensuring privacy.
                </p>

                <p className="font-bold">Requirements</p>
                <p className="text-gray-700 mb-5">Android 9+ OS</p>

                <p className="font-bold">Follow Steps below to complete</p>
                <p className="text-gray-700">1. Watch video on top.</p>
                <p className="text-gray-700 mb-10">
                  2. Please log into your eAssist email on your mobile device,
                  it will prompt you to set up the work profile. (If you're
                  already logged in, locate the email from Autobots and click on
                  the link provided.)
                </p>

                <div className="w-full flex justify-center">
                  <button
                    onClick={completeAndroid}
                    className="px-8 py-3 bg-blue-600 text-white font-semibold text-sm rounded hover:shadow-lg hover:bg-blue-700 disabled:bg-gray-400 disabled:shadow-none"
                  >
                    Complete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {submitted && (
        <div class="flex items-center justify-center h-full">
          <div>
            <div class="flex flex-col items-center space-y-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-green-600 w-28 h-28"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h1 class="text-4xl font-bold">Thank you !</h1>
              {formData.platform === "Android" ? (
                <p class="text-center">
                  Please open your eAssist email on your mobile to complete set
                  up.
                </p>
              ) : (
                <p class="text-center">
                  Thank you for submitting Zilla Download Request ! you will be
                  notified through email with instructions to get install Zilla
                  on your Mobile !
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppOnboarding;
