import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ResultBox from "../utility/ResultBox";
import { makeTwilioNumber } from "../../functions/util/format";
import {
  verify,
  addOfficeNumber,
} from "../../functions/office/editOfficeNumber";
import "./edit-office-number-styling.css";

const VerifyCallerId = () => {
  const { officeId, officeSysName } = useParams();
  const [officeNumber, setOfficeNumber] = useState("");
  const [showVerifyResult, setShowVerifyResult] = useState(false);
  const [verifyResultMessage, setVerifyResultMessage] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [showUpdateResult, setShowUpdateResult] = useState(false);
  const [updateResultMessage, setUpdateResultMessage] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const sendVerification = async () => {
    try {
      setVerifyResultMessage("");
      setVerifySuccess(false);
      setShowVerifyResult(false);
      setUpdateResultMessage("");
      setUpdateSuccess(false);
      setShowUpdateResult(false);

      if (!officeNumber) {
        setVerifyResultMessage("Missing fields: Please input a number.");
        setVerifySuccess(false);
        setShowVerifyResult(true);
      } else {
        let formattedNumber = makeTwilioNumber(officeNumber);

        if (formattedNumber.isValid) {
          setVerifyResultMessage("Sending...");
          setVerifySuccess(true);
          setShowVerifyResult(true);

          setOfficeNumber(formattedNumber.number);

          let result = await verify(officeNumber);

          if (result.result !== 0) {
            setVerifyResultMessage("Error: Verification failed to send.");
            setVerifySuccess(false);
          } else {
            setVerifyResultMessage(
              `Verification code: ${result.code} Calling now...`
            );
            setVerifySuccess(true);
          }
        } else {
          setVerifyResultMessage(
            "Error: Invalid phone number, please check, correct, and try again."
          );
          setVerifySuccess(false);
          setShowVerifyResult(true);
        }
      }
    } catch (err) {
      setVerifyResultMessage("Error: Verification failed to send.");
      setVerifySuccess(false);
      setShowVerifyResult(true);
    }
  };

  const handleUpdate = async () => {
    try {
      setUpdateResultMessage("");
      setUpdateSuccess(false);
      setShowUpdateResult(false);

      if (!officeNumber) {
        setUpdateResultMessage("Missing fields: Please input a number.");
        setUpdateSuccess(false);
        setShowUpdateResult(true);
      } else {
        setUpdateResultMessage("Updating...");
        setUpdateSuccess(true);
        setShowUpdateResult(true);

        let result = await addOfficeNumber(officeNumber, officeId);

        if (result.result !== 0) {
          if (result.result === 1) {
            setUpdateResultMessage("Error: Office number failed to update.");
            setUpdateSuccess(false);
          } else if (result.result === 3) {
            setUpdateResultMessage(
              "Phone number has not been validated. Please try again."
            );
            setUpdateSuccess(false);
          }
        } else {
          setUpdateResultMessage(
            `Success: Office phone number has been updated.`
          );
          setUpdateSuccess(true);
        }
      }
    } catch (err) {
      setUpdateResultMessage("Error: Office number failed to update.");
      setUpdateSuccess(false);
      setShowUpdateResult(true);
    }
  };

  return (
    <>
      <div className="edit-office-num-wrapper">
        <div className="form-container">
          <h3 className="form-title">Verify Caller ID for {officeSysName}</h3>
          <p>*THIS IS FOR DENTAL BILLING OFFICES ONLY*</p>
          <div className="instructions">
            <ol>
              <li className="instruction-item">
                Before completing these steps, please ensure you are currently
                on the phone or in real-time contact with an office
                representative.
              </li>
              <li className="instruction-item">
                Input the office phone number below and click the "Send
                Verification" button.
              </li>
              <li className="instruction-item">
                A verification code will appear on your screen, and the office
                phone will IMMEDIATELY recieve a phone call.
              </li>
              <li className="instruction-item">
                Give the verification code to the office representative to enter
                on their keypad.
              </li>
              <li className="instruction-item">
                If successful, they will hear a success response. If not
                successful, please send another verification and try again.
              </li>
            </ol>
          </div>
          <p className="form-item">Office Phone Number</p>
          <input
            onChange={(e) => setOfficeNumber(e.target.value)}
            placeholder="10 digits only i.e. 8011231234"
            className="form-input"
          />
          {showVerifyResult && (
            <ResultBox message={verifyResultMessage} success={verifySuccess} />
          )}
          <button className="form-btn" onClick={() => sendVerification()}>
            Send Verification
          </button>

          {verifySuccess ? (
            <>
              <div className="instructions">
                <h4 className="instructions-title">Update Office Number</h4>
                <p style={{ textAlign: "center", padding: "5px" }}>
                  Once you confirm the office successfully verified their phone
                  number, you may now click "Update" to update the office phone
                  number. It can now be used as a Caller Id!
                </p>
              </div>
              <button
                className="form-btn"
                style={{ float: "center" }}
                onClick={() => handleUpdate()}
              >
                Update
              </button>
              {showUpdateResult && (
                <ResultBox
                  message={updateResultMessage}
                  success={updateSuccess}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default VerifyCallerId;
