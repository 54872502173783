import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { updateUserAssignment } from "../../functions/office/officeUserFuncs";
import ResultBox from "../utility/ResultBox";
import { fetchSingleUser } from "../../functions/user/getUsers";
import { useNavigate } from "react-router";

const UpdateAssignmentContainer = () => {
  const { officeSysName, officeId, userId } = useParams();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [dbChecked, setDbChecked] = useState(false);
  const [pbChecked, setPbChecked] = useState(false);
  const [fsChecked, setFsChecked] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [confirmActive, setConfirmActive] = useState(false);

  const handleUpdate = async () => {
    if (!dbChecked && !pbChecked && !fsChecked) {
      alert("An assignment type must be selected.");
    } else if (dbChecked || pbChecked || fsChecked) {
      const res = await updateUserAssignment(
        officeId,
        userId,
        dbChecked,
        pbChecked,
        fsChecked
      );

      if (res) {
        setResultMessage("Success: Assignment was successfully updated");
        setSuccess(true);
        setShowResult(true);
      } else {
        setResultMessage("Error: Failed to update assignment");
        setSuccess(false);
        setShowResult(true);
      }

      setTimeout(() => {
        navigate("/viewUserInfo");
      }, 2000);
    } else {
      alert("There was an error with selection.");
    }
  };

  const showConfirm = () => {
    setConfirmActive(true);
  };

  const reset = () => {
    setResultMessage("");
    setShowResult(false);
    setSuccess(false);
    setConfirmActive(false);
  };

  useEffect(() => {
    const getUserEmail = async () => {
      const res = await fetchSingleUser(userId);

      if (res) {
        setUserEmail(res.email);
      }
    };

    getUserEmail();
  }, []);

  return (
    <div className="form-wrapper">
      <div className="form-container">
        <p className="form-title-sm">
          Update Assignment for {userEmail} in {officeSysName} to
        </p>

        <label className="form-checkbox">
          <input
            type="checkbox"
            checked={dbChecked}
            onChange={() => setDbChecked(!dbChecked)}
          />
          Dental Billing
        </label>
        <label className="form-checkbox">
          <input
            type="checkbox"
            checked={pbChecked}
            onChange={() => setPbChecked(!pbChecked)}
          />
          Patient Billing
        </label>
        <label className="form-checkbox">
          <input
            type="checkbox"
            checked={fsChecked}
            onChange={() => setFsChecked(!fsChecked)}
          />
          Full Schedule
        </label>

        {confirmActive ? (
          <>
            <p>Are you sure you want to update?</p>
            <div className="form-btn-container">
              <button className="form-btn" onClick={() => handleUpdate()}>
                Update assignment
              </button>
              <button className="form-btn" onClick={reset}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <button className="form-btn" onClick={showConfirm}>
            Update assignment
          </button>
        )}
        {showResult && (
          <div>
            <ResultBox message={resultMessage} success={success} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateAssignmentContainer;
