import { FaArrowLeft } from "react-icons/fa";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./go-back-styles.css";

const GoBack = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {location !== "/" && (
        <div className="go-back-container">
          <button className="go-back-arrow" onClick={goBack} title="Go Back">
            <FaArrowLeft size={20} />
          </button>
        </div>
      )}
    </>
  );
};

export default GoBack;
