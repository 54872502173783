import React from "react";
import "./loading-styles.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-wrapper">
        <div className="lds-dual-ring" />
      </div>
    </div>
  );
};

export default Loading;
