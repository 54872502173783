import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ResultBox from "../utility/ResultBox";
import { makeTwilioNumber } from "../../functions/util/format";
import { editNumber } from "../../functions/user/editNumber.js";

const EditUserNumber = () => {
  const { userId, userEmail } = useParams();
  const [userNumber, setUserNumber] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const sendUpdate = async () => {
    try {
      setResultMessage("");
      setSuccess(false);
      setShowResult(false);

      if (!userNumber) {
        setResultMessage("Missing fields: Please input a number.");
        setSuccess(false);
        setShowResult(true);
      } else {
        let formattedNumber = makeTwilioNumber(userNumber);

        if (formattedNumber.isValid) {
          setResultMessage("Updating...");
          setSuccess(true);
          setShowResult(true);

          setUserNumber(formattedNumber.number);

          let result = await editNumber(formattedNumber.number, userId);

          if (result.result !== 0) {
            if (result.result === 4 && result.type === "phone verify")
              setResultMessage(
                "Error: Phone number could not be verified. Please make sure this is a valid mobile phone and try again."
              );
            else setResultMessage("Error: Number failed to update.");
            setSuccess(false);
          } else {
            setResultMessage(`Success: Phone number was updated.`);
            setSuccess(true);
          }
        } else {
          setResultMessage("Error: Invalid phone number, please try again.");
          setSuccess(false);
          setShowResult(true);
        }
      }
    } catch (err) {
      setResultMessage("Error: Number failed to update.");
      setSuccess(false);
      setShowResult(true);
    }
  };

  const checkInputs = () => {
    setResultMessage("");
    setSuccess(false);
    setShowResult(false);
    setShowConfirm(false);

    if (!userNumber) {
      setResultMessage("Empty Field: Please input a phone number.");
      setSuccess(false);
      setShowResult(true);
    } else {
      setShowConfirm(true);
    }
  };

  const reset = () => {
    setResultMessage("");
    setSuccess(false);
    setShowResult(false);
    setShowConfirm(false);
  };

  return (
    <>
      <div className="form-wrapper">
        <div className="form-container">
          <h3 className="form-title">Edit Phone Number for {userEmail}</h3>
          <p className="form-item">Enter New Phone Number</p>
          <input
            onChange={(e) => setUserNumber(e.target.value)}
            placeholder="10 digits only i.e. 8011231234"
            className="form-input"
            maxLength={10}
          />
          <div className="form-error-message-container">
            {showConfirm ? (
              <>
                <p className="form-error-message-text">
                  Are you sure you want to save {userNumber}?
                </p>
                <div className="form-btn-container">
                  <button className="form-btn" onClick={() => sendUpdate()}>
                    Save
                  </button>
                  <button className="form-btn" onClick={reset}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <button className="form-btn" onClick={checkInputs}>
                Save
              </button>
            )}
            {showResult && (
              <>
                <ResultBox message={resultMessage} success={success} />
                <button className="form-btn" onClick={reset}>
                  Remove Message
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUserNumber;
