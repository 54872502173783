import React, { useState } from "react";
import { deleteUser } from "../../functions/user/updateUsers";
import { useNavigate } from "react-router";

const DeleteUserModal = ({ setDeleteUserModal, userId }) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState(
    "Are you sure you want to delete this user?"
  );
  const [showConfirmBtn, setShowConfirmBtn] = useState(true);

  const handleClose = () => {
    setDeleteUserModal(false);
    navigate(`/usersHome`);
  };

  const confirmed = async () => {
    const result = await deleteUser(userId);
    if (result) {
      setMessage(`Success: User deleted.`);
      setShowConfirmBtn(false);
    } else {
      setMessage("Error: Failed to delete this user.");
      setShowConfirmBtn(false);
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={() => handleClose()} />
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h5 className="modal-heading">Confirmation</h5>
          </div>
          <div className="modal-message">{message}</div>
          <div className="modal-btns">
            {showConfirmBtn && (
              <button className="modal-confirm-btn" onClick={() => confirmed()}>
                Confirm
              </button>
            )}
            <button className="modal-cancel-btn" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteUserModal;
