import React, { useState } from "react";
import { addNumber } from "../../functions/office/officeTwilioNumber";
import { useNavigate } from "react-router-dom";

const AddTwilioModal = ({ setIsOpen, officeId, twilioNumber }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(
    "Are you sure you want " +
      twilioNumber +
      "? eAssist will be charged for using this number. "
  );
  const [showConfirmBtn, setShowConfirmBtn] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    navigate(`/viewOfficeInfo`);
  };

  const confirmed = async () => {
    let phoneNumber = twilioNumber;
    const result = await addNumber(officeId, phoneNumber);

    if (result.result !== 0) {
      setMessage("Error: Failed to add Twilio number to office.");
      setShowConfirmBtn(false);
    } else {
      setMessage(`Success: Twilio number added to office.`);
      setShowConfirmBtn(false);
    }
  };

  return (
    <>
      <div
        className="modal-overlay"
        style={{ opacity: "0.5" }}
        onClick={() => handleClose()}
      >
        <div className="modal-wrapper">
          <div className="modal">
            <div className="modal-header">
              <h5 className="modal-heading">Confirmation</h5>
            </div>
            <div className="modal-message">{message}</div>
            <div className="modal-btns">
              {showConfirmBtn && (
                <button
                  className="modal-confirm-btn"
                  onClick={() => confirmed()}
                >
                  Confirm
                </button>
              )}
              <button
                className="modal-cancel-btn"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTwilioModal;
