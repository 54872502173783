import React, { useState } from "react";
import ResultBox from "../utility/ResultBox";
import { addUser } from "../../functions/user/addUser";
import { makeTwilioNumber } from "../../functions/util/format";
import Select from "react-select";
import "./create-user-styling.css";
import { useNavigate } from "react-router";

const CreateUser = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [messageActive, setMessageActive] = useState(false);
  const [resultMessage, setResultMessage] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState(null);
  const [options] = useState([
    {
      value: 1,
      label: "SC",
    },
    {
      value: 2,
      label: "TL",
    },
    {
      value: 3,
      label: "IT",
    },
  ]);

  const resetMessages = () => {
    setMessageActive(false);
    setResultMessage(null);
    setShowResult(false);
  };

  const sendCreate = async () => {
    setResultMessage("Creating User...");
    setShowResult(true);

    const res = await addUser(
      email,
      firstName,
      lastName,
      phoneNumber,
      selectedAccess.value
    );

    if (res) {
      setResultMessage("Success: New user was added");
      setSuccess(true);
      setShowResult(true);
    } else {
      setResultMessage("Error: Failed to add new user");
      setSuccess(false);
      setShowResult(true);
    }

    setTimeout(() => {
      navigate("/createUser");
    }, 2000);
  };

  const checkInputs = async () => {
    setMessageActive(false);
    setResultMessage(null);

    if (!firstName || !lastName || !email || !phoneNumber || !selectedAccess) {
      setMessageActive(true);
      setResultMessage("Missing information: please fill out all inputs");
    } else {
      let formattedNumber = makeTwilioNumber(phoneNumber);

      if (formattedNumber.isValid) {
        setPhoneNumber(formattedNumber.number);
        setShowCreate(true);
      } else {
        setMessageActive(true);
        setResultMessage("Number is not valid, please check it and try again.");
      }
    }
  };

  return (
    <div className="create-user-wrapper">
      <div className="form-container">
        <h3 className="form-title">Create User</h3>
        <p className="form-item">First Name</p>
        <input
          className="form-input"
          onChange={(e) =>
            setFirstName(
              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
            )
          }
          style={{ textTransform: "capitalize" }}
        />
        <p className="form-item">Last Name</p>
        <input
          className="form-input"
          onChange={(e) =>
            setLastName(
              e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
            )
          }
          style={{ textTransform: "capitalize" }}
        />
        <p className="form-item">eAssist.me Email</p>
        <input
          className="form-input"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          style={{ textTransform: "lowercase" }}
        />
        <p className="form-item">Phone</p>
        <input
          className="form-input"
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="ex. 8011231234"
        />
        <p className="form-item">Access Level</p>
        <Select
          className="form-input"
          options={options}
          onChange={setSelectedAccess}
          placeholder={"Select access level"}
        />
        {messageActive ? (
          <>
            <h4 className="form-error-message-text">{resultMessage}</h4>
            <button className="form-btn" onClick={() => resetMessages()}>
              Remove Message
            </button>
          </>
        ) : null}
        {showCreate ? (
          <div className="confirm-sc-removal">
            <p>Are you sure you want to create this user?</p>
            <div className="confirm-sc-removal-inner">
              <button className="form-btn" onClick={() => sendCreate()}>
                Create User
              </button>
              <button className="form-btn" onClick={() => setShowCreate(false)}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <button
            className="form-btn"
            style={{ marginTop: "1em" }}
            onClick={checkInputs}
          >
            Create User
          </button>
        )}
        {showResult && (
          <div className="form-error-message-container">
            <ResultBox message={resultMessage} success={success} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateUser;
