import React, { useState, useEffect } from "react";
import Recording from "./Recording";
import "./comm-records-styling.css";
import {
  BsFillArrowUpCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";

const RecordingsContainer = ({ recordings, reload }) => {
  const [recordingsArray, setRecordingsArray] = useState(recordings);
  const [message, setMessage] = useState("");
  const [ascending, setAscending] = useState(false);

  useEffect(() => {
    const sortedRecordings = [...recordingsArray].sort((a, b) => {
      if (ascending) {
        return a.recordingStart.localeCompare(b.recordingStart);
      } else {
        return b.recordingStart.localeCompare(a.recordingStart);
      }
    });

    setRecordingsArray(sortedRecordings);
  }, [ascending, recordings]);

  useEffect(() => {
    setTimeout(() => {
      setMessage("No recordings to display.");
    }, 15000);
  }, []);

  return (
    <div className="comm-records-container">
      {recordings.length > 0 ? (
        <>
          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th
                    className="table-heading"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px",
                    }}
                  >
                    <p>Date</p>
                    <div className="table-heading-icon-wrapper">
                      {ascending === true ? (
                        <BsFillArrowUpCircleFill
                          size={18}
                          color="#022536"
                          onClick={() => setAscending(false)}
                          title="Oldest to Newest"
                        />
                      ) : (
                        <BsFillArrowDownCircleFill
                          size={18}
                          color="#022536"
                          onClick={() => setAscending(true)}
                          title="Newest to Oldest"
                        />
                      )}
                    </div>{" "}
                  </th>
                  <th className="table-heading">Time</th>
                  <th className="table-heading">Type</th>
                  <th className="table-heading">From</th>
                  <th className="table-heading">To</th>
                  <th className="table-heading">Duration</th>
                  <th className="table-heading">Play/Download</th>
                  <th className="table-heading">Archive</th>
                </tr>
              </thead>
              <tbody>
                {recordingsArray.map((recording) => (
                  <Recording
                    key={recording._id}
                    recording={recording}
                    reload={reload}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <p>*Time: 24hr timestamp shown in local timezone.</p>{" "}
        </>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default RecordingsContainer;
