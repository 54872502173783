import React,{ useState, useEffect } from 'react';
import Select from 'react-select';
import { getAllUsers } from '../../functions/user/getUsers';

const UserDropdown = ({ handleChange }) => {

    const [options, setOptions] = useState([{ label: "loading", value: null }]);
    const [type, setType] = useState("activated");

    useEffect(() => {
        const getOptions = async () => {
            const users = await getAllUsers(type);

            let newOptions = [];
            for (let user of users) {
                newOptions.push({ 
                    label: user.email,
                    value: user._id
                });
            };
            setOptions(newOptions);
        };

        getOptions();
    }, [type]);

    return (
        <div>
            <Select
                options={[
                    { value: "activated", label: "Activated" },
                    { value: "deactivated", label: "Deactivated" },
                    { value: "all", label: "All" }
                ]}
                onChange={e => setType(e.value)}
                placeholder="Activated"
            /><br/>
            <Select 
                options={options}
                onChange={e => handleChange(e.value)}
                placeholder="Select a user"
            />
        </div>
    )
}

export default UserDropdown