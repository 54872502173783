import { get, callDelete, post } from "../util/utilFunc";

export const fetchVoicemails = async (officeId) => {
  try {
    const res = await get(`/office/allVoicemails/${officeId}`, {}, {});

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const fetchCalls = async (officeId) => {
  try {
    const res = await get(`/office/calls/${officeId}`, {}, {});

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const submitTrustHub = async (officeId, body) => {
  try {
    const res = await post(`/office/trusthub/${officeId}`, {}, body);
    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const submitCallTrust = async (officeId, body) => {
  try {
    const res = await post(`/office/calltrust/${officeId}`, {}, body);
    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const fetchTrustHub = async (officeId) => {
  try {
    const res = await get(`/office/trusthub/${officeId}`, {}, {});
    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const fetchRecordings = async (officeId) => {
  try {
    const res = await get(`/office/allRecordings/${officeId}`, {}, {});

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const fetchCallLogs = async (officeId, limit = 10) => {
  try {
    const res = await get(
      `/office/calllogs/${officeId}?limit=${limit}`,
      {},
      {}
    );
    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const archiveRecording = async (voicemailId) => {
  try {
    const res = await callDelete(`/office/recording/${voicemailId}`, {}, {});

    if (res.success) return true;
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//fetches conversations and checks if the response containes a conversations array
//if true then returns the conversations else returns an empty array
export const fetchConversations = async (officeId) => {
  const res = await get(`/message/conversations?id=${officeId}`, {});

  if (res.conversations) {
    return res.conversations;
  }

  return [];
};

//fetches messages for a given conversationId if the response containes a messages array
//if true then returns messages array else returns empty array
export const fetchMessages = async (conversationId) => {
  const res = await get(`/message?id=${conversationId}`, {});

  if (res.messages) {
    return res.messages;
  }

  return [];
};
