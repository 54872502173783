import React from 'react';
import paragraphs from './paragraphs';
import Paragraph from './Paragraph';

const PrivacyPolicyContainer = () => {
    return (
        <div>
            <h4>
                Zilla.me Privacy Policy
            </h4>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "2%", marginRight: "2%" }}>
                {paragraphs.map((paragraph) => 
                    <Paragraph 
                    key={paragraph.id}
                    content={paragraph.content}
                    header={paragraph.header}
                    />
                )}
            </div>
        </div>
    )
}

export default PrivacyPolicyContainer