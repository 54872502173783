import React, { useState } from "react";
import { removeOfficeFromUser } from "../../functions/office/officeUserFuncs";

const RemoveAssignmentModal = ({
  setRemoveAssignmentModal,
  officeId,
  userId,
  reload,
}) => {
  const [message, setMessage] = useState(
    "Are you sure you want to remove this assignment?"
  );
  const [showConfirmBtn, setShowConfirmBtn] = useState(true);

  const handleClose = () => {
    setRemoveAssignmentModal(false);
    reload();
  };

  const confirmed = async () => {
    const result = await removeOfficeFromUser(officeId, userId);

    if (result) {
      setShowConfirmBtn(false);
      setMessage(`Success: Assignment removed.`);
    } else {
      setShowConfirmBtn(false);
      setMessage("Error: Failed to remove the assignment.");
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={() => handleClose()} />
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h5 className="modal-heading">Confirmation</h5>
          </div>
          <div className="modal-message">{message}</div>
          <div className="modal-btns">
            {showConfirmBtn && (
              <button className="modal-confirm-btn" onClick={() => confirmed()}>
                Confirm
              </button>
            )}
            <button className="modal-cancel-btn" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveAssignmentModal;
