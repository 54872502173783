import { post, put } from "../util/utilFunc";

export const resetDevice = async (userId) => {
  try {
    const res = await post(`/user/unregister/${userId}`, {}, {});
    if (res.result === 0) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const resetMobileDevice = async (userId) => {
  try {
    const res = await put(`/user/${userId}`, {}, { devices: [] });
    if (res.result === 0) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};
