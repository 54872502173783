import { post } from "../util/utilFunc";

export const sendPasswordReset = async (email) => {
    try {
        const res = await post(`/auth/recover`, {}, {
            email
        });
        if (res.result === 0) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
};