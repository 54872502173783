import React, { useEffect, useState } from "react";
import OfficesDropdown from "../dropdowns/OfficesDropdown";
import CommTypeDropdown from "../dropdowns/CommTypeDropdown";
import "./comm-records-styling.css";
import {
  fetchConversations,
  fetchRecordings,
  fetchCalls,
  fetchVoicemails,
  fetchCallLogs,
} from "../../functions/office/commRecords";
import VoicemailsContainer from "./VoicemailsContainer";
import CallsContainer from "./CallsContainer";
import RecordingsContainer from "./RecordingsContainer";
import TextConversationsContainer from "./TextConversationsContainer";
import Loading from "../loading/Loading";
import LogsContainer from "./LogsContainer";

const OfficeCommRecordsContainer = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedCommType, setSelectedCommType] = useState(null);
  const [bothSelected, setBothSelected] = useState(false);
  const [voicemails, setVoicemails] = useState([]);
  const [calls, setCalls] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [conversations, setConversations] = useState([
    {
      _id: "",
      customerNumber: "",
    },
  ]);

  const [callLogs, setCallLogs] = useState([]);

  const fireRefresh = () => {
    setRefresh(refresh + 1);
  };
  const handleOfficeChange = (officeId) => {
    setSelectedOffice(officeId);
    setVoicemails([]);
    setRecordings([]);
    setCalls([]);
    setBothSelected(false);
  };

  const handleCommTypeChange = (commType) => {
    setSelectedCommType(commType);
    setVoicemails([]);
    setRecordings([]);
    setCalls([]);
    setBothSelected(false);
  };

  const getVoicemails = async () => {
    const res = await fetchVoicemails(selectedOffice);

    if (res) {
      setVoicemails(res.voicemails);
      setLoading(false);
    } else {
      alert("Error: Failed to fetch voicemails.");
    }
  };

  const getCalls = async () => {
    const res = await fetchCalls(selectedOffice);

    if (res) {
      setCalls(res.calls);
      setLoading(false);
    } else {
      alert("Error: Failed to fetch calls.");
    }
  };

  const getRecordings = async () => {
    const res = await fetchRecordings(selectedOffice);
    if (res) {
      setRecordings(res.recordings);
      setLoading(false);
    } else {
      alert("Error: Failed to fetch recordings.");
    }
  };

  const getConversations = async () => {
    const conversationsFromServer = await fetchConversations(selectedOffice);
    setConversations(conversationsFromServer);
    setLoading(false);
  };

  const getCallLogs = async () => {
    const logsFromServer = await fetchCallLogs(selectedOffice, 100);
    setCallLogs(logsFromServer);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCommType != null && selectedCommType != null) {
      setBothSelected(true);
      setLoading(true);
    }
  }, [selectedOffice, selectedCommType]);

  useEffect(() => {
    if (selectedOffice && selectedCommType === "voicemails") {
      getVoicemails();
    } else if (selectedOffice && selectedCommType === "calls") {
      getCalls();
    } else if (selectedOffice && selectedCommType === "allRecordings") {
      getRecordings();
    } else if (selectedOffice && selectedCommType === "textMessages") {
      getConversations();
    } else if (selectedOffice && selectedCommType === "callLogs") {
      getCallLogs();
    }
  }, [bothSelected, refresh]);

  return (
    <div className="comm-wrapper">
      <div className="comm-cards-wrapper">
        <div className="comm-half-container">
          <div className="comm-card-content-container">
            <p className="comm-title">Select an Office</p>
            <div style={{ width: "80%" }}>
              <OfficesDropdown handleChange={handleOfficeChange} />
            </div>
          </div>
        </div>
        <div className="comm-half-container">
          <div className="comm-card-content-container">
            <p className="comm-title">Select Communication Record Type</p>
            <div style={{ width: "80%" }}>
              <CommTypeDropdown handleChange={handleCommTypeChange} />
            </div>
          </div>
        </div>
      </div>
      {loading === true ? <Loading /> : null}
      {selectedCommType === "voicemails" && voicemails.length > 0 ? (
        <VoicemailsContainer voicemails={voicemails} reload={fireRefresh} />
      ) : null}
      {selectedCommType === "allRecordings" && recordings.length > 0 ? (
        <RecordingsContainer recordings={recordings} reload={fireRefresh} />
      ) : null}
      {selectedCommType === "calls" && calls.length > 0 ? (
        <CallsContainer calls={calls} reload={fireRefresh} />
      ) : null}
      {selectedCommType === "textMessages" ? (
        <TextConversationsContainer conversations={conversations} />
      ) : null}
      {selectedCommType === "callLogs" ? (
        <LogsContainer calls={callLogs} />
      ) : null}
    </div>
  );
};

export default OfficeCommRecordsContainer;
