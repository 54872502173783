import { post, put, callDelete } from "../util/utilFunc";

export const addNumber = async (officeId, phoneNumber) => {
  try {
    const res = await post(
      `/office/twilioNumber/${officeId}`,
      {},
      {
        phoneNumber: phoneNumber,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const updateNumber = async (officeId, phoneNumber) => {
  try {
    const res = await put(
      `/office/twilioNumber/${officeId}`,
      {},
      {
        phoneNumber: phoneNumber,
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};

export const removeNumber = async (officeId) => {
  try {
    const res = await callDelete(`/office/twilioNumber/${officeId}`, {}, {});

    return res;
  } catch (error) {
    console.log(error);
    return { result: 1 };
  }
};
