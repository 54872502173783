import { post } from "../util/utilFunc";

export const addUser = async (email, firstName, lastName, phoneNumber, access) => {
    try {
        const res = await post(`/user`, {}, {
            email,
            firstName,
            lastName,
            phoneNumber,
            access
        });
        if (res.result === 0) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
};