import React from "react";
import Select from "react-select";

const CommTypeDropdown = ({ handleChange }) => {
  let options = [
    { label: "Phone Calls & Voicemails", value: "allRecordings" },

    { label: "Phone Calls", value: "calls" },
    { label: "Voicemails", value: "voicemails" },
    { label: "Text Messages", value: "textMessages" },
    { label: "Call Logs", value: "callLogs" },
  ];

  return (
    <div>
      <Select options={options} onChange={(e) => handleChange(e.value)} />
    </div>
  );
};

export default CommTypeDropdown;
