import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./offices-home-styling.css";

const OfficesHome = () => {
  const [access, setAccess] = useState("");

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("access");
        setAccess(token);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);

  return (
    <div className="offices-home-wrapper">
      <h2 className="offices-home-title">Office Management</h2>
      <div className="offices-home-btns-wrapper">
        <Link to="/viewOfficeInfo" className="offices-home-btn">
          <p className="offices-home-btn-text">View Office</p>
        </Link>

        <Link to="/officeCommRecords" className="offices-home-btn">
          <p className="offices-home-btn-text">Office Communication Records</p>
        </Link>
        {parseInt(access) === 3 ? (
          <Link to="/assignUserToOffice" className="offices-home-btn">
            <p className="offices-home-btn-text">Assign Offices</p>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default OfficesHome;
