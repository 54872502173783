//Takes in phone number in form 777 777 7777 or (777) 777-7777 or 7777777777
//or anything similar and turns it into form +17777777777 so that it can be used
//by twilio. returns an object that contains a boolean value if number is valid or
//not and the formatted number
export const makeTwilioNumber = (rawNumber) => {
  //remove spaces
  const noSpaces = rawNumber.replace(/\s+/g, "");
  //make into an array
  const numArray = noSpaces.split("");

  //check if the number begins with +1 or begins with a one or a zero
  //because that would mean they're trying to start with a country code
  //which would be fine but we can't count on that happening everytime
  //remove plus and 1 or 0 from start
  if (numArray[0] === "+") {
    numArray.shift();
    if (numArray[0] === "1" || numArray[0] === "0") {
      numArray.shift();
    }
  } else {
    if (numArray[0] === "1" || numArray[0] === "0") {
      numArray.shift();
    }
  }
  //make number a string again
  const newNumber = numArray.join("");
  //remove any () in the number
  const removeFirstParthenses = newNumber.replace(/[(]/g, "");
  const removeSecondParthenses = removeFirstParthenses.replace(/[)]/g, "");
  //remove any - in the number
  const removeDash = removeSecondParthenses.replace(/-/g, "");
  //remove any + in the number if they weren't at the start for whatever reason
  const strippedNumber = removeDash.replace(/[+]/g, "");
  //check if number is 10 digits long after all uneeded characters are removed
  //if true return that the number is valid and return the number with +1 in front
  //else return that the number is not valid and return the strippedNumber
  if (strippedNumber.length !== 10) {
    return { isValid: false, number: strippedNumber };
  } else {
    return { isValid: true, number: `+1${strippedNumber}` };
  }
};

//Takes in a date ISO string and turns it into a string with
//the date formated 1/1/22
export const formatDate = (dateString) => {
  const rawDate = new Date(dateString);
  const month = rawDate.getMonth() + 1;
  const date = rawDate.getDate();
  const fullYear = rawDate.getFullYear().toString();
  const year = fullYear.split("");
  return `${month}/${date}/${year[2]}${year[3]}`;
};

export const formatDateTime = (d) => {
  const date = new Date(d);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
};

export const formatTime = (dateString) => {
  const rawTime = new Date(dateString);
  const hour = String(rawTime.getHours()).padStart(2, "0");
  const minute = String(rawTime.getMinutes()).padStart(2, "0");
  return `${hour}:${minute}`;
};

//Takes in a phoneNumber in the format +17777777777 and formats it to
//appear +1 (777) 777-7777
function onlyLettersAndSpaces(str) {
  return /^[A-Za-z\s]*$/.test(str);
}

export const formatPhoneNumber = (rawNumber) => {
  let number;
  if (onlyLettersAndSpaces(rawNumber)) {
    number = rawNumber;
    return rawNumber;
  } else {
    const rawArr = rawNumber.split("");
    rawArr.splice(2, 0, " ");
    rawArr.splice(3, 0, "(");
    rawArr.splice(7, 0, ")");
    rawArr.splice(8, 0, " ");
    rawArr.splice(12, 0, "-");
    const finalNumber = rawArr.join("");
    number = finalNumber;
  }
  return number;
};

//Takes seconds and formats it to 1:15 format or if it is less than
//1 minute to 30s format
export const secondsToMinutes = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  } else {
    const minutes = Math.floor(seconds / 60);
    let newSeconds = (seconds - minutes * 60).toString();
    return `${minutes}m:${
      newSeconds.length > 1 ? newSeconds : "0" + newSeconds
    }s`;
  }
};

export const formatType = (type) => {
  if (type === "outbound") {
    return "Outbound Call";
  }
  if (type === "inbound") {
    return "Inbound Call";
  }
  if (type === "voicemail" || type === "flagVoicemail") {
    return "Voicemail";
  }
};

export const backgroundColor = (flagged, type) => {
  if (flagged === "red") {
    return "#f73838"; //redish
  } else if (flagged === "grayishBlue") {
    return "#eff6fa"; //greyish
  } else if (flagged === "yellow") {
    return "#fff8f0"; //yellowish
  } else if (flagged === "blue") {
    return "#cdebf7"; //blueish
  } else if (flagged === "green") {
    return "#cdf7db"; //blueish
  } else if (flagged === "lightRed") {
    return "#fad7d4"; //blueish
  }
};
