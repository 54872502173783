import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "./slices/headerSlice";
// import officeSlice from "./slices/officeSlice";

export const store = configureStore({
  reducer: {
    header: headerSlice,
    // office: officeSlice
  },
});
