import React, { useEffect, useState } from "react";
import { fetchMessages } from "../../functions/office/commRecords";
import { useParams } from "react-router-dom";
import TextConvoDatetime from "./TextConvoDatetime";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import TextHelpModal from "../modals/TextHelpModal";
import { fetchOfficeInfo } from "../../functions/office/generalOfficeFuncs";
import { formatPhoneNumber } from "../../functions/util/format";

const TextConversation = () => {
  const { id, customerNumber, officeId } = useParams();
  const [messages, setMessages] = useState([]);
  const [textHelpModal, setTextHelpModal] = useState(false);
  const [officeName, setOfficeName] = useState("");
  const [formattedCustNum, setFormattedCustNum] = useState("");

  useEffect(() => {
    const getMessages = async () => {
      const messagesFromServer = await fetchMessages(id);
      setMessages(messagesFromServer);
    };
    getMessages();

    const getNumbers = async () => {
      const res = await fetchOfficeInfo(officeId);
      if (res) {
        setOfficeName(res.officeSysName);
      } else {
        alert("Error: Failed to get office info");
      }
      const number = await formatPhoneNumber(customerNumber);
      setFormattedCustNum(number);
    };
    getNumbers();
  }, []);
  return (
    <div className="comm-wrapper">
      <div
        className="comm-cards-wrapper"
        style={{
          justifyContent: "center",
          maxHeight: "80vh",
        }}
      >
        <div className="comm-display-conversation-container">
          <div className="comm-conversation-header">
            <p>{formattedCustNum}</p>
            <p>{officeName}</p>
          </div>
          <div className="comm-display-messages-container">
            {" "}
            <div className="comm-scrollable">
              {messages.map((message) => (
                <>
                  {" "}
                  {message.type === "outbound" ? (
                    <div className="comm-message-container">
                      <p className="comm-outbound-message-text">
                        {message.body}
                      </p>
                      <p className="comm-outbound-message-datetime">
                        <TextConvoDatetime
                          key={message._id}
                          createdAt={message.createdAt}
                        />
                      </p>
                    </div>
                  ) : (
                    <div className="comm-message-container">
                      <p className="comm-inbound-message-text">
                        {message.body}
                      </p>
                      <div className="comm-inbound-message-datetime">
                        <TextConvoDatetime
                          key={message._id}
                          createdAt={message.createdAt}
                        />
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div>
          <BsFillQuestionCircleFill
            size={20}
            color="#D3D3D3"
            className="comm-help-btn"
            onClick={() => {
              setTextHelpModal(true);
            }}
          />
        </div>
        {textHelpModal && <TextHelpModal setTextHelpModal={setTextHelpModal} />}
      </div>
    </div>
  );
};

export default TextConversation;
