import React, { useState } from "react";
import "./header-styles.css";
import LinksContainer from "./LinksContainer";
import LogoContainer from "./LogoContainer";
import HeaderMini from "./HeaderMini";

const HeaderContainer = ({ isLogin }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const breakpoint = 1150;

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleResize);

  let narrowView = width <= breakpoint ? true : false;

  return (
    <div className="header-container">
      <LogoContainer />
      {narrowView ? (
        <HeaderMini isLogin={isLogin} />
      ) : (
        <LinksContainer isLogin={isLogin} />
      )}
    </div>
  );
};

export default HeaderContainer;
