import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import "../viewUserInfo/view-user-info-styling.css";
import RemoveAssignmentModal from "../modals/RemoveAssignmentModal";

const UserOffice = ({
  userId,
  officeId,
  officeSysName,
  reload,
  dbService,
  pbService,
  fsService,
}) => {
  const [removeAssignmentModal, setRemoveAssignmentModal] = useState(false);
  let navigate = useNavigate();
  return (
    <tbody>
      <tr>
        <td className="view-user-table-heading">
          <p>{officeSysName}</p>
        </td>
        <td className="table-item">
          {dbService && <p>DB</p>}
          {pbService && <p>PB</p>}
          {fsService && <p>FS</p>}
        </td>
        <td className="view-user-table-icon-container">
          <AiFillEdit
            size={25}
            color="#023047"
            onClick={() =>
              navigate(
                `/updateAssigment/${officeSysName}/${officeId}/${userId}`,
                { replace: true }
              )
            }
          />
        </td>
        <td className="view-user-table-icon-container">
          <AiFillDelete
            size={25}
            color="#fb8500"
            onClick={() => setRemoveAssignmentModal(true)}
          />
          {removeAssignmentModal && (
            <RemoveAssignmentModal
              setRemoveAssignmentModal={setRemoveAssignmentModal}
              officeId={officeId}
              userId={userId}
              reload={reload}
            />
          )}
        </td>
      </tr>
    </tbody>
  );
};

export default UserOffice;
