import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import "./view-office-info-styling.css";
import RemoveAssignmmentModal from "../modals/RemoveAssignmentModal";

const OfficeUser = ({
  userId,
  userEmail,
  devices,
  officeId,
  reload,
  officeSysName,
  dbService,
  pbService,
  fsService,
}) => {
  const [removeAssignmentModal, setRemoveAssignmentModal] = useState(false);
  let navigate = useNavigate();
  const [access, setAccess] = useState("");

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("access");
        setAccess(token);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);

  return (
    <tbody>
      <tr>
        <td className="table-item">
          <p>{userEmail}</p>
        </td>
        <td className="table-item">
          {dbService && <p>DB</p>}
          {pbService && <p>PB</p>}
          {fsService && <p>FS</p>}
        </td>
        <td
          className={
            devices.length !== 0 &&
            devices[devices.length - 1].appVer !== "1.6.0" // TODO : get App ver from backend
              ? "table-item red"
              : "table-item"
          }
        >
          {devices.length !== 0 && devices[devices.length - 1].appVer}
        </td>
        {parseInt(access) === 3 ? (
          <>
            {" "}
            <td className="table-icon-container">
              <AiFillEdit
                size={25}
                color="#023047"
                className="table-icon"
                onClick={() =>
                  navigate(
                    `/updateAssigment/${officeSysName}/${officeId}/${userId}`,
                    { replace: true }
                  )
                }
              />
            </td>
            <td className="table-icon-container">
              <AiFillDelete
                size={25}
                color="#fb8500"
                className="table-icon"
                onClick={() => setRemoveAssignmentModal(true)}
              />
            </td>{" "}
          </>
        ) : null}
        {removeAssignmentModal && (
          <RemoveAssignmmentModal
            setRemoveAssignmentModal={setRemoveAssignmentModal}
            officeId={officeId}
            userId={userId}
            reload={reload}
          />
        )}
      </tr>
    </tbody>
  );
};

export default OfficeUser;
