import React, { useState } from "react";
import UserDropdown from "../dropdowns/UserDropdown";
import CommTypeDropdown from "../dropdowns/CommTypeDropdown";
import "../officeCommRecords/comm-records-styling.css";
// import VoicemailsContainer from "../officeCommRecords/VoicemailsContainer";

const UserCommRecordsContainer = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedCommType, setSelectedCommType] = useState("");

  const handleUserChange = (userId) => {
    setSelectedUser(userId);
  };

  const handleCommTypeChange = (commType) => {
    setSelectedCommType(commType);
  };

  // const getVoicemails = async () => {
  //   const res = await fetchVoicemails(selectedOffice);

  //   if (res) {
  //     setVoicemails(res.voicemails);
  //   } else {
  //     alert("Error: Failed to fetch voicemails.");
  //   }
  // };

  // useEffect(() => {
  //   if (selectedOffice && selectedCommType) {
  //     getVoicemails();
  //   }
  // }, [selectedOffice, selectedCommType]);

  return (
    <div className="comm-wrapper">
      <div className="comm-cards-wrapper">
        <div className="comm-half-container">
          <div className="comm-card-content-container">
            <p className="comm-title">Select a User</p>
            <div style={{ width: "80%" }}>
              <UserDropdown handleChange={handleUserChange} />
            </div>
          </div>
        </div>

        <div className="comm-half-container">
          <div className="comm-card-content-container">
            <p className="comm-title">Select Communication Record Type</p>
            <div style={{ width: "80%" }}>
              <CommTypeDropdown handleChange={handleCommTypeChange} />
            </div>
          </div>
        </div>
      </div>
      {/* <VoicemailsContainer voicemails={voicemails} /> */}
      <p>User Communication Records via personal twilio numbers coming soon.</p>
    </div>
  );
};

export default UserCommRecordsContainer;
