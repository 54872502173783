import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/zillaImg.JPG";
import "./header-styles.css";

const LogoContainer = () => {
  return (
    <Link to="/" className="header-logo-container">
      <img
        src={logo}
        alt="logo"
        style={{ maxWidth: "3.5em", maxHeight: "3.5em", borderRadius: 100 }}
      />
    </Link>
  );
};

export default LogoContainer;
