import React, { useState } from "react";
import { login } from "../../functions/login/loginFunc";
import Verify from "./Verify";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const handleLogin = async () => {
    setErrorMessage("");

    const res = await login(email, password);

    if (res.token) {
      setShowVerify(true);
      setUserInfo(res);
    } else {
      // Error
      if (res.result === 1) {
        setErrorMessage("Login Failed");
      }
      // Wrong password
      else if (res.result === 4 || res.type === "password") {
        setErrorMessage("Invalid Password");
      }
      // Wrong email
      else if (res.result === 3) {
        setErrorMessage("Email address is not associated with an account.");
      }
      // SC access Level 1 not authorized
      else if (res.result === 6) {
        setErrorMessage(
          "Only users with IT or TL access can access Zilla Web."
        );
      } else setErrorMessage("Failed to log you in");
    }
  };

  return (
    <div className="form-wrapper" style={{ marginLeft: "65px" }}>
      <div className="form-container">
        <img
          className="site__logo"
          src={require("../../images/zillaImg.JPG")}
          alt="Eassist Icon"
          style={{
            height: "8em",
            width: "8em",
            padding: "1em",
            borderRadius: 100,
          }}
        />
        <h2 className="login_title">Sign In</h2>

        <input
          placeholder="email"
          className="form-input"
          style={{ maxWidth: "60%", margin: "0.3em", fontWeight: "normal" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          placeholder="password"
          className="form-input"
          style={{ maxWidth: "60%", margin: "0.3em", fontWeight: "normal" }}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
        />
        {showVerify ? (
          <Verify userInfo={userInfo} />
        ) : (
          <button className="form-btn" onClick={() => handleLogin()}>
            Sign in
          </button>
        )}
        <div className="form-error-message-container">
          <p className="form-error-message-text">{errorMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
