import React from "react";
import { Link } from "react-router-dom";
import { BsPeople } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import "./homepage-styles.css";

const Homepage = () => {
  return (
    <div className="homepage-container">
      <h1 className="homepage-title">Welcome to Zilla</h1>
      <div className="homepage-button-links">
        <Link to="/usersHome" className="homepage-users-btn">
          <BsPeople size={50} className="people-icon" />
          <p className="homepage-btn-text">User Management</p>
        </Link>
        <Link to="/officesHome" className="homepage-offices-btn">
          <HiOutlineOfficeBuilding size={50} className="people-icon" />
          <p className="homepage-btn-text">Office Management</p>
        </Link>
      </div>
    </div>
  );
};

export default Homepage;
