import React, { useState } from "react";
import { searchTwilioNumbers } from "../../functions/office/searchTwilioNumbers";
import { useParams } from "react-router-dom";
import UpdateTwilioModal from "../modals/UpdateTwilioModal";
import AddTwilioModal from "../modals/AddTwilioModal";
import { makeTwilioNumber } from "../../functions/util/format";
import { useEffect } from "react";

const SearchTwilio = () => {
  const { officeId, officeSysName, officePhoneNumber, twilioNumber } =
    useParams();
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [messageActive, setMessageActive] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [twilioActive, setTwilioActive] = useState(false);
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [selectedTwilioNumber, setSelectedTwilioNumber] = useState("");
  const [noResultMessage] = useState("No search results found");
  const [isOpen, setIsOpen] = useState(false);
  const [formattedOfficeNumber, setFormattedOfficeNumber] = useState("");
  const [inputtedOfficeNumber, setInputtedOfficeNumber] = useState("");

  useEffect(() => {
    
    const formatNumber = async () => {
      try
      {
        let formattedNumber = makeTwilioNumber(officePhoneNumber);

        if (formattedNumber.isValid) {

          setFormattedOfficeNumber(formattedNumber.number);

        } else {
          setFormattedOfficeNumber("N/A")
        }
      }

      catch (err)
      {
        setLoadingActive(false);
        setLoadingMessage("");
        setMessageActive(true);
        setResultMessage("Error: Something went wrong");
      }
    };

    formatNumber();

  }, [officePhoneNumber]);

  const handleChange = (number) => {
    setIsOpen(true);
    setSelectedTwilioNumber(number);
  };

  const reset = () => {
    setResultMessage("");
    setLoadingMessage("");
    setMessageActive(false);
    setLoadingActive(false);
    setTwilioActive(false);
    setTwilioNumbers([]);
    setSelectedTwilioNumber("");
  };

  const checkInputs = async () => {
    setMessageActive(false);
    setResultMessage("");
    setLoadingActive(false);
    setLoadingMessage("");

    let formattedNumber = makeTwilioNumber(inputtedOfficeNumber);

    if (formattedNumber.isValid) {

      setInputtedOfficeNumber(formattedNumber.number);

    }

    if (!inputtedOfficeNumber || !city || !state || !areaCode) {
      setMessageActive(true);
      setResultMessage("Missing information: please fill out all inputs");
    } else if (!formattedNumber.isValid) {
      setLoadingActive(false);
      setLoadingMessage("");
      setMessageActive(true);
      setResultMessage("Error: Invalid phone number, please check, correct, and try again.");
    } else {
      setLoadingMessage("Searching...");
      setLoadingActive(true);
      getTwilioNumbers();
    }
  };

  const getTwilioNumbers = async () => {
    try {
      // Call route to search for numbers
      let result = await searchTwilioNumbers(
        inputtedOfficeNumber,
        city,
        state,
        areaCode
      );

      // If the result was not a success
      if (result.result !== 0) {
        setLoadingActive(false);
        setLoadingMessage("");
        setMessageActive(true);
        setResultMessage("Error: search failed");
      }

      // The search was successful
      else {
        setLoadingActive(false);
        setLoadingMessage("");
        setTwilioNumbers(result.numbers);
        setTwilioActive(true);
      }
    } catch (err) {
      setLoadingActive(false);
      setLoadingMessage("");
      setMessageActive(true);
      setResultMessage("Error: search failed");
    }
  };

  return (
    <div className="col-content-wrapper">
      <div
        className="col-content-container"
        style={{
          backgroundColor: "white",
          borderColor: "white",
          margin: "15px",
        }}
      >
        <p className="col-title">Office Information</p>
        <div className="table-container" style={{ maxWidth: "80%" }}>
          <table className="table">
            <tr>
              <td className="table-heading">Office Systematic Name:</td>
              <td className="table-item">{officeSysName}</td>
            </tr>
            <tr>
              <td className="table-heading">Office Number:</td>
              <td className="table-item">{formattedOfficeNumber}</td>
            </tr>
            <tr>
              <td className="table-heading">Twilio Number:</td>
              <td className="table-item">
                {twilioNumber ? twilioNumber : "N/A"}
              </td>
            </tr>
          </table>
        </div>
        <br />
        <p className="col-title">Search for an Available Twilio Number</p>
        <div className="form-container" style={{ maxHeight: "500px" }}>
          <p className="form-item">Office Phone Number</p>
          <input
            className="form-input"
            placeholder="10 digits only, i.e. 8011231234"
            maxLength={10}
            onChange={(e) => setInputtedOfficeNumber(e.target.value)}
          />
          <p className="form-item">City</p>
          <input
            className="form-input"
            onChange={(e) => setCity(e.target.value)}
            placeholder="e.g. Houston"
          />
          <p className="form-item">State</p>
          <input
            className="form-input"
            onChange={(e) => setState(e.target.value)}
            placeholder="Two letter abbreviation only i.e. TX"
            maxLength={2}
            type="text"
          />
          <p className="form-item">Area Code</p>
          <input
            className="form-input"
            onChange={(e) => setAreaCode(e.target.value)}
            placeholder="e.g. 840"
            maxLength={5}
          />
          {messageActive ? (
            <>
              <h4>{resultMessage}</h4>
            </>
          ) : null}
          <div className="confirm-container">
            <button className="form-btn" onClick={checkInputs}>
              Search
            </button>
            {twilioActive ? (
              <button className="form-btn" onClick={reset}>
                Clear Search
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="col-content-container"
        style={{
          backgroundColor: "white",
          borderColor: "white",
          margin: "15px",
        }}
      >
        <div className="col-card-container">
          {loadingActive ? (
            <>
              <h4 style={{ marginBottom: "2em" }}>{loadingMessage}</h4>
            </>
          ) : null}
          {twilioActive ? (
            twilioNumbers.length > 0 ? (
              <>
                <p className="col-title">Available Twillio Numbers</p>
                <div className="table-container" style={{ width: "100%" }}>
                  <table className="table">
                    <tr>
                      <td className="table-item"></td>
                      <td className="table-heading">Twilio Number</td>
                      <td className="table-heading">City</td>
                      <td className="table-heading">State</td>
                      <td className="table-heading">Zipcode</td>
                    </tr>
                    {twilioNumbers.map((number) => (
                      <tr>
                        <td
                          className="table-item"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            className="twilio-btn"
                            name="twilio_number"
                            value={number.phoneNumber}
                            key={`${number.phoneNumber}_input`}
                            onClick={(e) => handleChange(e.target.value)}
                          >
                            Select
                          </button>
                          {/* Open a modal depending on whether the office has an existing twilioNumber */}
                          {isOpen && twilioNumber && (
                            <UpdateTwilioModal
                              setIsOpen={setIsOpen}
                              officeId={officeId}
                              twilioNumber={selectedTwilioNumber}
                            />
                          )}
                          {isOpen && !twilioNumber && (
                            <AddTwilioModal
                              setIsOpen={setIsOpen}
                              officeId={officeId}
                              twilioNumber={selectedTwilioNumber}
                            />
                          )}
                        </td>
                        <td className="table-item">
                          <p
                            style={{ fontWeight: "bold" }}
                            key={`${number.phoneNumber}_friendlyName`}
                          >
                            {number.friendlyName}
                          </p>
                        </td>
                        <td className="table-item">
                          <p key={`${number.phoneNumber}_locality`}>
                            {number.locality}
                          </p>
                        </td>

                        <td className="table-item">
                          <p key={`${number.phoneNumber}_region`}>
                            {number.region}
                          </p>
                        </td>
                        <td className="table-item">
                          <p key={`${number.phoneNumber}_postalCode`}>
                            {number.postalCode}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </>
            ) : (
              <h4 style={{ marginBottom: "2em" }}>{noResultMessage}</h4>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SearchTwilio;
