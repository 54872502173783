import React, { useState, useEffect } from "react";
import { searchOffices } from "../../functions/office/generalOfficeFuncs";
import Select from "react-select";

const OfficesDropdown = ({ handleChange }) => {
  const [search, setSearch] = useState("");
  const [offices, setOffices] = useState([]);
  const [optionsMessage, setOptionsMessage] = useState("Loading...");

  const onInputChange = (input, { action }) => {
    if (action === "input-change") {
      setSearch(input);
    }
  };

  useEffect(() => {
    const getOffices = async () => {
      try {
        const offices = await searchOffices(search);

        let newOffices = [];
        for (let office of offices) {
          newOffices.push({
            value: office._id,
            label: office.officeSysName,
          });
        }

        setOffices(newOffices);

        if (newOffices.length === 0) {
          setOptionsMessage("No offices were found.");
        } else {
          setOptionsMessage("Loading...");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getOffices();
  }, [search]);

  return (
    <div>
      <Select
        onChange={(e) => handleChange(e.value)}
        isSearchable={true}
        options={offices}
        onInputChange={(input, { action }) => onInputChange(input, { action })}
        noOptionsMessage={() => optionsMessage}
      />
    </div>
  );
};

export default OfficesDropdown;
