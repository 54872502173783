import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OfficeDropdown from "../dropdowns/OfficesDropdown";
import { fetchOfficeInfo } from "../../functions/office/generalOfficeFuncs";
import { fetchOfficeUsers } from "../../functions/office/officeUserFuncs";
import OfficeUser from "./OfficeUser";
import "./view-office-info-styling.css";
import DeactivateTwilioModal from "../modals/DeactivateTwilioModal";
import DeactivateCallerIdModal from "../modals/DeactivateCallerIdModal";
import { fetchTrustHub } from "../../functions/office/commRecords";

const ViewOfficeInfoContainer = () => {
  const navigate = useNavigate();
  const [selectedOffice, setSelectedOffice] = useState("");
  const [officeInfo, setOfficeInfo] = useState({});
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deactivateCallerIdModal, setDeactivateCallerIdModal] = useState(false);
  const [access, setAccess] = useState("");
  const [status, setStatus] = useState("Not Submitted Yet");

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("access");
        setAccess(token);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);

  const handleOfficeChange = (officeId) => {
    setSelectedOffice(officeId);
  };

  const getOfficeInfo = async () => {
    const res = await fetchOfficeInfo(selectedOffice);

    if (res) {
      setOfficeInfo(res);
      fo(res._id);
    } else {
      alert("Error: Failed to get office info");
    }
  };

  const getOfficeUsers = async () => {
    const res = await fetchOfficeUsers(selectedOffice);

    if (res) {
      setAssignedUsers(res);
    } else {
      alert("Error: Failed to get offices users");
    }
  };

  const fireRefresh = () => {
    setRefresh(refresh + 1);
  };

  const fo = async (id) => {
    console.log("Fetching trusthub", id);
    const res = await fetchTrustHub(id);

    setStatus("Not Submitted Yet");

    if (res) {
      if (res.state) {
        setStatus(res.state.status);
      }
    } else {
      alert("Error: Failed to get office info");
    }
  };

  // useEffect(() => {
  //   if (officeInfo._id) {
  //     console.log("Office is ", officeInfo);
  //     fo(officeInfo._id);
  //   }
  // }, [officeInfo]);

  useEffect(() => {
    if (selectedOffice !== "") {
      getOfficeUsers();
    }
  }, [selectedOffice, refresh]);

  useEffect(() => {
    if (selectedOffice !== "") {
      getOfficeInfo();
    }
  }, [selectedOffice, deactivateModal, deactivateCallerIdModal]);

  const checkTwilioInputs = () => {
    const officeNumber =
      officeInfo.phoneNumber === "" ? "None" : officeInfo.phoneNumber;

    if (!!officeInfo.twilioNumber) {
      navigate(
        `/searchTwilio/${selectedOffice}/${officeInfo.officeSysName}/${officeNumber}/${officeInfo.twilioNumber}`
      );
    } else if (officeInfo.twilioNumber === "") {
      navigate(
        `/searchTwilio/${selectedOffice}/${officeInfo.officeSysName}/${officeNumber}`
      );
    } else {
      alert("Something went wrong");
    }
  };

  const checkVerifyCallerId = () => {
    navigate(`/verifyCallerId/${selectedOffice}/${officeInfo.officeSysName}`);
  };

  const twillioOnboarding = () => {
    navigate(`/twillioOnboarding/${selectedOffice}`);
  };

  const callTrustOnboarding = () => {
    navigate(`/twillioTrustProduct/${selectedOffice}`);
  };

  return (
    <div className="view-office-container">
      <div className="view-office-half-container">
        <p className="view-office-title">Select an Office</p>
        <div style={{ width: "65%" }}>
          <OfficeDropdown handleChange={handleOfficeChange} />
        </div>
        {officeInfo.officeSysName && (
          <div className="view-office-card-content-container">
            <p className="view-office-title">Assigned Users</p>
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <td className="table-item">Email</td>
                    <td className="table-item">Assignment</td>
                    <td className="table-item">App Ver.</td>
                  </tr>
                </thead>
                {assignedUsers.map((user) => (
                  <OfficeUser
                    key={user.email}
                    userId={user._id}
                    userEmail={user.email}
                    devices={user.devices}
                    officeId={selectedOffice}
                    officeSysName={officeInfo.officeSysName}
                    reload={fireRefresh}
                    dbService={user.dbService}
                    pbService={user.pbService}
                    fsService={user.fsService}
                  />
                ))}{" "}
              </table>
            </div>
          </div>
        )}
      </div>

      {officeInfo.officeSysName && (
        <div className="view-office-half-container">
          <div className="view-office-card-content-container">
            <p className="view-office-title">Office Information</p>
            <div className="table-container">
              <table className="table">
                <tr>
                  <td className="table-heading">Office Systematic Name:</td>
                  <td className="table-item">{officeInfo.officeSysName}</td>
                </tr>
                <tr>
                  <td className="table-heading">Office Name:</td>
                  <td className="table-item">{officeInfo.officeName}</td>
                </tr>
                <tr>
                  <td className="table-heading">Caller ID Number:</td>
                  <td className="table-item">
                    {officeInfo.callerIdVerified
                      ? officeInfo.callerIdNumber
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td className="table-heading">Caller ID Verified:</td>
                  <td className="table-item">
                    {officeInfo.callerIdVerified ? "Yes" : "No"}
                  </td>
                </tr>
                <tr>
                  <td className="table-heading">Active Office:</td>
                  <td className="table-item">
                    {officeInfo.active ? "True" : "False"}
                  </td>
                </tr>{" "}
                <tr>
                  <td className="table-heading">Business Profile Status</td>
                  <td className="table-item">{status}</td>
                </tr>
              </table>
            </div>
            <br />

            <div className="view-office-twillio-container">
              <p className="view-office-title">Twillio Number</p>
              <p className="view-office-twillio">
                {officeInfo.twilioActivated ? officeInfo.twilioNumber : "N/A"}
              </p>
              {parseInt(access) === 3 ? (
                <>
                  {officeInfo.twilioActivated ? (
                    <div className="view-office-btns-container">
                      <button
                        className="view-office-btn"
                        onClick={() => checkTwilioInputs()}
                      >
                        Edit
                      </button>
                      <button
                        className="view-office-btn"
                        onClick={() => setDeactivateModal(true)}
                      >
                        Deactivate
                      </button>
                    </div>
                  ) : (
                    <button
                      className="view-office-btn"
                      onClick={() => checkTwilioInputs()}
                    >
                      1. Activate
                    </button>
                  )}
                </>
              ) : null}
              {deactivateModal && (
                <DeactivateTwilioModal
                  setDeactivateModal={setDeactivateModal}
                  officeId={selectedOffice}
                />
              )}
              {parseInt(access) === 3 && (
                <div className="view-office-btns-container">
                  <button
                    className="view-office-btn"
                    onClick={() => twillioOnboarding()}
                  >
                    2. Create Business Profile
                  </button>
                </div>
              )}
              {/* {parseInt(access) === 3 && (
                <div className="view-office-btns-container">
                  <button
                    className="view-office-btn"
                    onClick={() => callTrustOnboarding()}
                  >
                    Submit Call Trust
                  </button>
                </div>
              )} */}
              {parseInt(access) === 3 ? (
                <>
                  {officeInfo.callerIdNumber === "" ? (
                    <div className="view-office-btns-container">
                      <button
                        className="view-office-btn"
                        onClick={() => checkVerifyCallerId()}
                      >
                        3. Add Verified Called ID (DB)
                      </button>
                    </div>
                  ) : (
                    <div className="view-office-btns-container">
                      <button
                        className="view-office-btn"
                        onClick={() => checkVerifyCallerId()}
                      >
                        Edit Verified Caller ID
                      </button>
                      <button
                        className="view-office-btn"
                        onClick={() => setDeactivateCallerIdModal(true)}
                      >
                        Disable Verified Caller ID
                      </button>
                      {deactivateCallerIdModal && (
                        <DeactivateCallerIdModal
                          officeId={selectedOffice}
                          setDeactivateCallerIdModal={
                            setDeactivateCallerIdModal
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewOfficeInfoContainer;
