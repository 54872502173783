import React, { useEffect, useState } from "react";
import { formatPhoneNumber, formatDate } from "../../functions/util/format";
import { useNavigate } from "react-router-dom";
import "./comm-records-styling.css";

const TextConversationBtns = ({ _id, customerNumber, officeId, convoDate }) => {
  const navigate = useNavigate();
  const [formattedNumber, setFormattedNumber] = useState("");
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const formatting = async () => {
      const num = await formatPhoneNumber(customerNumber);
      setFormattedNumber(num);
      const date = await formatDate(convoDate);
      setFormattedDate(date);
    };
    formatting();
  }, []);

  const handleClick = () => {
    navigate(`/textConversation/${_id}/${customerNumber}/${officeId}`);
  };
  return (
    <button className="comm-text-convo-btn" onClick={() => handleClick()}>
      <div className="comm-text-btns-container">
        <div className="comm-text-btn-font">{formattedNumber}</div>
        <div className="comm-text-btn-font">{formattedDate}</div>
      </div>
    </button>
  );
};

export default TextConversationBtns;
