import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOfficeInfo } from "../../functions/office/generalOfficeFuncs";
import {
  fetchTrustHub,
  submitTrustHub,
  submitCallTrust,
} from "../../functions/office/commRecords";
import "./twillioOnboarding.css";
import Select from "react-select";
import Loading from "../loading/Loading";

const TwillioOnboarding = () => {
  const navigate = useNavigate();
  const { officeId } = useParams();
  const [officeInfo, setOfficeInfo] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [access, setAccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    business_name: "",
    website_url: "",
    business_type: "",
    business_registration_number: "",
    email: "autobots@gmail.com",
    representative: {
      job_position: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      business_title: "",
    },
    address: {
      street: "",
      city: "",
      region: "",
      postalCode: "",
    },
    cname: "",
    callVolume: 50,
    employee: 10,
  });

  const [status, setStatus] = useState("Not Submitted Yet");

  const businessType = [
    {
      value: "Sole Proprietorship",
      label: "Sole Proprietorship",
    },
    {
      value: "Partnership",
      label: "Partnership",
    },
    {
      value: "Limited Liability Corporation",
      label: "Limited Liability Corporation",
    },
    {
      value: "Co-operative",
      label: "Co-operative",
    },
    {
      value: "Non-profit Corporation",
      label: "Non-profit Corporation",
    },
    {
      value: "Corporation",
      label: "Corporation",
    },
  ];

  const jobPosition = [
    {
      value: "Director",
      label: "Director",
    },
    {
      value: "GM",
      label: "GM",
    },
    {
      value: "VP",
      label: "VP",
    },
    {
      value: "CEO",
      label: "CEO",
    },
    {
      value: "CFO",
      label: "CFO",
    },
    {
      value: "General Counsel",
      label: "General Counsel",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("access");
        setAccess(token);
      } catch (error) {
        console.log(error);
      }
    };

    getUser();
  }, []);

  const getOfficeInfo = async (id) => {
    setLoading(true);
    const res = await fetchOfficeInfo(id);
    setLoading(false);
    if (res) {
      setOfficeInfo(res);
      fo(officeId, res.officeName);
    } else {
      alert("Error: Failed to get office info");
    }
  };

  const fo = async (id, officeName) => {
    const res = await fetchTrustHub(id);

    if (res) {
      if (res.office) {
        res.office.cname = res.office.cname || officeName.substring(0, 15);
        setFormData(res.office);
      }
      if (res.state) {
        setStatus(res.state.status);
      }
    } else {
      alert("Error: Failed to get office info");
    }
  };

  useEffect(() => {
    getOfficeInfo(officeId);
  }, [officeId]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (loading) return false;

    if (formData.business_type === "") {
      return alert("Please Select the Business Type ");
    }

    if (formData.representative.job_position === "") {
      return alert("Please Select the Job Position ");
    }
    setLoading(true);

    const res = await submitTrustHub(officeId, formData);
    if (res.message) {
      alert(res.message);
    }
    setLoading(false);
  };

  const submitCallTrustForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await submitCallTrust(officeId, formData);
    if (res.message) {
      alert(res.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="container">
        <p className="view-office-title">Office Information</p>
        <div className="table-container m-auto">
          <table className="table">
            <tr>
              <td className="table-heading">Office Systematic Name:</td>
              <td className="table-item">{officeInfo.officeSysName}</td>
            </tr>
            <tr>
              <td className="table-heading">Office Name:</td>
              <td className="table-item">{officeInfo.officeName}</td>
            </tr>
            {/* <tr>
              <td className="table-heading">Caller ID Number:</td>
              <td className="table-item">
                {officeInfo.callerIdVerified
                  ? officeInfo.callerIdNumber
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="table-heading">Caller ID Verified:</td>
              <td className="table-item">
                {officeInfo.callerIdVerified ? "Yes" : "No"}
              </td>
            </tr> */}
            <tr>
              <td className="table-heading">Onboarding Status</td>
              <td className="table-item">{status}</td>
            </tr>
          </table>
        </div>
      </div>
      {!loading && (
        <div className="view-office-container">
          {officeInfo.officeSysName && (
            <div className="view-office-half-container m-auto mt-5">
              <div className="w-full">
                <form name="trustHubForm mt-5" onSubmit={submitForm}>
                  <p className="view-office-title mt-5">Business Address</p>

                  <div className="flex">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Street*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.address.street}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                street: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">City*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.address.city}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                city: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">
                          State Abreviation (E.g.: NY or WA) :*
                        </p>
                        <input
                          className="form-input w-full"
                          value={formData.address.region}
                          minLength={2}
                          maxLength={2}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                region: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Postal Code*:</p>
                        <input
                          className="form-input w-full"
                          type="number"
                          value={formData.address.postalCode}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                postalCode: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <p className="view-office-title">Business Information</p>

                  <br />

                  <div className="px-10 mt-5">
                    <div className="fc">
                      <p className="form-item label">Legal Business Name*:</p>
                      <input
                        className="form-input w-full"
                        value={formData.business_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            business_name: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Business Identity*:</p>
                        <input
                          className="form-input w-full"
                          value={"Direct Customer"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Business Region*:</p>
                        <input
                          className="form-input w-full"
                          value={"USA and Canada"}
                          disabled
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Business Type*:</p>
                        <Select
                          className="form-input p-0"
                          options={businessType}
                          value={businessType.filter(
                            (e) => e.value === formData.business_type
                          )}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              business_type: e.value,
                            })
                          }
                          placeholder={"Select Business Type"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">
                          Business Registration Number*: (9 Digit Numerical)
                        </p>
                        <input
                          className="form-input w-full"
                          type="text"
                          value={formData.business_registration_number}
                          pattern="[0-9]{9}"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              business_registration_number: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Business Industry*:</p>
                        <input
                          className="form-input w-full"
                          value={"Healthcare"}
                          disabled
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="flex mt-5">
                    {/* <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">
                          Business Registration ID Type*:
                        </p>
                        <input
                          className="form-input w-full"
                          value={"Employer Identification Number (EIN)"}
                          disabled
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Website URL*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.website_url}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              website_url: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Email to get updates:</p>
                        <input
                          className="form-input w-full"
                          value={formData.email}
                          type="email"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                  <br />

                  <br />
                  <p className="view-office-title">People To Contact</p>
                  <p className="text-center">
                    Twilio, the third party communication provider will contact
                    authorized representatives to verify identity and confirm
                    consent.
                  </p>

                  <br />
                  <div className="flex">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">First Name*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.representative.first_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                first_name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Last Name*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.representative.last_name}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                last_name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Business Title*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.representative.business_title}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                business_title: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Job Position*:</p>
                        <Select
                          className="form-input p-0"
                          options={jobPosition}
                          value={jobPosition.filter(
                            (e) =>
                              e.value === formData.representative.job_position
                          )}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                job_position: e.value,
                              },
                            })
                          }
                          placeholder={"Select Job Postion"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-5">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">
                          Phone (10 Digit Number Only)*:
                        </p>
                        <input
                          className="form-input w-full"
                          type="text"
                          pattern="[0-9]{10}"
                          value={formData.representative.phone_number}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                phone_number: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Email*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.representative.email}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              representative: {
                                ...formData.representative,
                                email: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="flex mt-5">
                    <button className="view-office-btn m-auto" type="submit">
                      Submit Profile
                    </button>
                  </div>
                </form>

                <br></br>
                <br></br>
                <hr></hr>
                <br></br>
                <form name="trustHubForm" onSubmit={submitCallTrustForm}>
                  <p className="view-office-title">Call Trust Onboarding</p>

                  <div className="px-10">
                    <div className="fc">
                      <p className="form-item label">
                        CNAM ( 15 Characters )*:
                      </p>
                      <input
                        className="form-input w-full"
                        value={formData.cname}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            cname: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>

                  <br />
                  <div className="flex">
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">Employee Count*:</p>
                        <input
                          className="form-input w-full"
                          value={formData.employee}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              employee: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="w-50 px-10">
                      <div className="fc">
                        <p className="form-item label">
                          Average day call Volume*:
                        </p>
                        <input
                          className="form-input w-full"
                          value={formData.callVolume}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              callVolume: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <br></br>
                  <br></br>
                  <div className="flex mt-5">
                    <button className="view-office-btn m-auto" type="submit">
                      Submit CNAME
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TwillioOnboarding;
