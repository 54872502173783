import React, { useEffect, useState } from "react";
import { addOfficeToUser } from "../../functions/office/officeUserFuncs";
import { useNavigate } from "react-router";

const MakeAssignmentModal = ({
  setMakeAssignmentModal,
  userId,
  officeId,
  dbChecked,
  pbChecked,
  fsChecked,
}) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState(
    "Are you sure you want to make this assignnment?"
  );
  const [showConfirmBtn, setShowConfirmBtn] = useState(false);

  const handleClose = () => {
    navigate(`/assignUserToOffice`);

    setMakeAssignmentModal(false);
  };

  useEffect(() => {
    const confirmation = async () => {
      if (
        officeId === "" ||
        userId === "" ||
        (dbChecked === false && pbChecked === false && fsChecked === false)
      ) {
        await setMessage(
          "Error: You must select both a user, an office, and an access level."
        );
      } else {
        setShowConfirmBtn(true);
        setMessage("Are you sure you want to make this assignnment?");
      }
    };
    confirmation();
  }, []);

  const confirmed = async () => {
    const res = await addOfficeToUser(officeId, {
      userId: userId,
      dbService: dbChecked,
      pbService: pbChecked,
      fsService: fsChecked,
    });

    if (res.result === 0) {
      setMessage("Success: User was successfully added to the office");
      setShowConfirmBtn(false);
    } else if (res.result === 3) {
      setMessage(
        "Error: Twilio active office not found. Make sure this office has been activated with a twilio number."
      );
      setShowConfirmBtn(false);
    } else {
      setMessage("Error: Failed to add user to office");
      setShowConfirmBtn(false);
    }
  };
  return (
    <>
      <div className="modal-overlay" onClick={() => handleClose()} />
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <h5 className="modal-heading">Confirmation</h5>
          </div>
          <div className="modal-message">{message}</div>
          <div className="modal-btns">
            {showConfirmBtn && (
              <button className="modal-confirm-btn" onClick={() => confirmed()}>
                Confirm
              </button>
            )}
            <button className="modal-cancel-btn" onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeAssignmentModal;
