import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./twillioOnboarding.css";
import Select from "react-select";
import Loading from "../loading/Loading";
import { post } from "../../functions/util/utilFunc";
import logo from "../../images/zillaImg.JPG";

const TwillioOnboardingPublic = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    officeSysName: "",
    business_name: "",
    website_url: "",
    business_type: "",
    business_registration_number: "",
    email: "autobots@gmail.com",
    representative: {
      job_position: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      business_title: "",
    },
    address: {
      street: "",
      city: "",
      region: "",
      postalCode: "",
    },
    cname: "",
    callVolume: 50,
    employee: 10,
  });

  const businessType = [
    {
      value: "Sole Proprietorship",
      label: "Sole Proprietorship",
    },
    {
      value: "Partnership",
      label: "Partnership",
    },
    {
      value: "Limited Liability Corporation",
      label: "Limited Liability Corporation",
    },
    {
      value: "Co-operative",
      label: "Co-operative",
    },
    {
      value: "Non-profit Corporation",
      label: "Non-profit Corporation",
    },
    {
      value: "Corporation",
      label: "Corporation",
    },
  ];

  const jobPosition = [
    {
      value: "Director",
      label: "Director",
    },
    {
      value: "GM",
      label: "GM",
    },
    {
      value: "VP",
      label: "VP",
    },
    {
      value: "CEO",
      label: "CEO",
    },
    {
      value: "CFO",
      label: "CFO",
    },
    {
      value: "General Counsel",
      label: "General Counsel",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.business_type === "") {
      return alert("Please Select the Business Type ");
    }

    if (formData.representative.job_position === "") {
      return alert("Please Select the Job Position ");
    }

    setLoading(true);
    const res = await post("/submitOfficeDetails", {}, formData);
    if (res.message) {
      alert(res.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}

      <div className="wrapper">
        <div className="text_container">
          <img src={logo} alt="logo" className="logo" />
          <p className="big_bold">ZILLA BUSINESS PROFILE REQUEST</p>
          <img
            src={
              "https://dentalbilling.com/wp-content/themes/dentalbilling/assets/images/eAssist-logo-outsourced-dental-billing-company.svg"
            }
            alt="eassit"
            className="eassist"
          />
        </div>
        <form name="trustHubForm" onSubmit={submitForm}>
          <div className="container">
            <div className="form-wrap">
              <h2>Contact Information</h2>
              <p className="subheading">
                Twilio, the third party communication provider, will contact
                your authorized representative to verify identity and consent to
                use Zilla masking your practice name. Please ensure they can be
                reached by email and phone.
              </p>

              <br />

              <div className="fc">
                <p className="form-item label">First Name*:</p>
                <input
                  className="form-input w-full"
                  value={formData.representative.first_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        first_name: e.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">Last Name*:</p>
                <input
                  className="form-input w-full"
                  value={formData.representative.last_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        last_name: e.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <br />

              <div className="fc">
                <p className="form-item label">Email*:</p>
                <input
                  className="form-input w-full"
                  value={formData.representative.email}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        email: e.target.value,
                      },
                    })
                  }
                  required
                />
                <p className="small-heading">
                  We'll never share your email with anyone else.
                </p>
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">Business Title*:</p>
                <input
                  className="form-input w-full"
                  value={formData.representative.business_title}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        business_title: e.target.value,
                      },
                    })
                  }
                  required
                />
                <p className="small-heading">Ex. Head of Product Management</p>
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">Job Position*:</p>
                <Select
                  className="form-input p-0"
                  options={jobPosition}
                  value={jobPosition.filter(
                    (e) => e.value === formData.representative.job_position
                  )}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        job_position: e.value,
                      },
                    })
                  }
                  placeholder={"Select"}
                  required={true}
                />
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">
                  Phone (10 Digit Number Only)*:
                </p>
                <input
                  className="form-input w-full"
                  type="text"
                  pattern="[0-9]{10}"
                  value={formData.representative.phone_number}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      representative: {
                        ...formData.representative,
                        phone_number: e.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="flex mt-5">
                <input type="checkbox" required /> &nbsp;
                <p className="small-heading">
                  I confirm that my nominated authorized representative agrees
                  to be contacted by Twilio.
                </p>
              </div>
            </div>

            <div className="form-wrap">
              <h2>Business Information</h2>
              <br />
              <div className="fc">
                <p className="form-item label">Office Systematic Name*:</p>

                <input
                  className="form-input w-full"
                  value={formData.officeSysName}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      officeSysName: e.target.value,
                    })
                  }
                  required
                />
                <p className="small-heading">
                  Consult an eAssist team member for this. E.g. st.city.lastname
                </p>
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">Legal Business Name*:</p>
                <input
                  className="form-input w-full"
                  value={formData.business_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      business_name: e.target.value,
                    })
                  }
                  required
                />
                <p className="small-heading">
                  Enter the exact legal business name, as registered with the
                  EIN. E.g. Twilio Inc. rather than Twilio.
                </p>
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">
                  Business Type*:
                  <br />
                </p>
                <Select
                  className="form-input p-0"
                  options={businessType}
                  value={businessType.filter(
                    (e) => e.value === formData.business_type
                  )}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      business_type: e.value,
                    })
                  }
                  placeholder={"Select"}
                  required={true}
                />
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">
                  Business Registration Number*: (9 Digit Numerical)
                </p>
                <input
                  className="form-input w-full"
                  type="text"
                  value={formData.business_registration_number}
                  pattern="[0-9]{9}"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      business_registration_number: e.target.value,
                    })
                  }
                  required
                />
                <p className="small-heading">
                  Enter the EIN Tax ID as it appears in the EIN listing.
                </p>
              </div>
              <br />
              <div className="fc">
                <p className="form-item label">Website URL*:</p>
                <input
                  className="form-input w-full"
                  value={formData.website_url}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      website_url: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <br /> <br />
              <div className="flex mt-5">
                <button className="view-office-btn m-auto w-full" type="submit">
                  Submit Profile
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TwillioOnboardingPublic;
