import serverUrl from "../../constants/serverUrl";

export const timeout = () => {
  alert("Timeout: You're session has timed out. Taking you back to login");
  localStorage.clear();
  window.location.reload();
};

const getNewAuthToken = async () => {
  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
  const res = await fetch(`${serverUrl}/auth/refreshToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  });

  const data = await res.json();

  if (data.result === 0) {
    localStorage.setItem("authToken", JSON.stringify(data.token));
    return 0;
  } else if (data.result === 4 && data.type === "expired token") {
    timeout();
    return 4;
  } else {
    return 1;
  }
};

export const get = async (path, headers) => {
  try {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const res = await fetch(`${serverUrl}${path}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
    });

    const data = await res.json();

    if (data.result === 1) {
      alert("Error a server error stopped this action from being completed");
    } else if (data.result === 4) {
      if (data.type === "expired token") {
        const refresh = await getNewAuthToken();
        if (refresh === 0) {
          return get(path, headers);
        } else if (refresh !== 4) {
          alert(
            "Error; There was a weird auth error. You will get another alert then be taken back to login"
          );
          timeout();
        }
      } else {
        alert("Unauthorized: You were not authorized to view this data");
      }
    } else {
      return data;
    }
  } catch (error) {
    alert("Error: Front end error stopped data from being fetched");
  }
};

export const post = async (path, headers, body) => {
  try {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const res = await fetch(`${serverUrl}${path}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify({
        ...body,
      }),
    });

    const data = await res.json();
    if (data.result === 1) {
      alert("Error: A server error stopped this action from being completed");
    } else if (data.result === 2) {
      alert(
        "Error: the email address entered is already associated with another account"
      );
    } else if (data.result === 4) {
      if (data.type === "expired token") {
        const refresh = await getNewAuthToken();
        if (refresh === 0) {
          return post(path, headers, body);
        } else if (refresh !== 4) {
          alert(
            "Error: There was a weird auth error. Taking you back to login"
          );
          timeout();
        }
      } else if (data.type === "unauthorized email") {
        alert(
          "Invalid domain: email you entered is not a valid eassist.me email"
        );
      } else {
        alert("Unauthorized: You were not authorized to perform this action");
      }
    } else {
      return data;
    }
  } catch (error) {
    alert("Error: Front end error stopped action from being performed");
  }
};

export const put = async (path, headers, body) => {
  try {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const res = await fetch(`${serverUrl}${path}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify({
        ...body,
      }),
    });

    const data = await res.json();

    if (data.result === 1) {
      alert("Error: A server error stopped this action from being completed");
    } else if (data.result === 4) {
      if (data.type === "expired token") {
        const refresh = await getNewAuthToken();
        if (refresh === 0) {
          return put(path, headers, body);
        } else if (refresh !== 4) {
          alert(
            "Error: There was a weird auth error. Taking you back to login"
          );
          timeout();
        }
      } else if (data.type === "phone verify") {
        return data;
      } else {
        alert("Unauthorized: You were not authorized to perform this action");
      }
    } else {
      return data;
    }
  } catch (error) {
    alert("Error: Front end error stopped action from being performed");
  }
};

export const patch = async (path, headers, body) => {
  try {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const res = await fetch(`${serverUrl}${path}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify({
        ...body,
      }),
    });

    const data = await res.json();

    if (data.result === 1) {
      alert("Error: A server error stopped this action from being completed");
    } else if (data.result === 4) {
      if (data.type === "expired token") {
        const refresh = await getNewAuthToken();
        if (refresh === 0) {
          return patch(path, headers, body);
        } else if (refresh !== 4) {
          alert(
            "Error: There was a weird auth error. Taking you back to login"
          );
          timeout();
        }
      } else {
        alert("Unauthorized: You were not authorized to perform this action");
      }
    } else {
      return data;
    }
  } catch (error) {
    alert("Error: Front end error stopped action from being performed");
  }
};

export const callDelete = async (path, headers, body) => {
  try {
    const authToken = JSON.parse(localStorage.getItem("authToken"));

    const res = await fetch(`${serverUrl}${path}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify({
        ...body,
      }),
    });

    const data = await res.json();

    if (data.result === 1) {
      alert("Error: A server error stopped this action from being completed");
    } else if (data.result === 4) {
      if (data.type === "expired token") {
        const refresh = await getNewAuthToken();
        if (refresh === 0) {
          return callDelete(path, headers, body);
        } else if (refresh !== 4) {
          alert(
            "Error: There was a weird auth error. Taking you back to login"
          );
          timeout();
        }
      } else {
        alert("Unauthorized: You were not authorized to perform this action");
      }
    } else {
      return data;
    }
  } catch (error) {
    alert("Error: Front end error stopped action from being performed");
  }
};
