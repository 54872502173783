import React, { useEffect, useState } from "react";
import {
  fetchSingleUser,
  getOfficesAssignedToUser,
} from "../../functions/user/getUsers";
import UserDropdown from "../dropdowns/UserDropdown";
import UserOffice from "./UserOffice";
import { useNavigate } from "react-router-dom";
import "./view-user-info-styling.css";
import ResetDeviceModal from "../modals/ResetDeviceModal";
import DeleteUserModal from "../modals/DeleteUserModal";
import ReactivateUserModal from "../modals/ReactivateUserModal";
import { resetMobileDevice } from "../../functions/user/resetDevice";

const ViewUserInfo = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [assignedOffices, setAssignedOffices] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [resetModal, setResetModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [reactivateUserModal, setReactivateUserModal] = useState(false);
  const handleSelectUser = (userId) => {
    setSelectedUser(userId);
  };

  const handleRefresh = () => {
    setRefresh(refresh + 1);
  };

  const getUser = async () => {
    const res = await fetchSingleUser(selectedUser);

    if (res) {
      setUserInfo(res);
    }
  };

  const getOffices = async () => {
    const officesFromServer = await getOfficesAssignedToUser(selectedUser);
    setAssignedOffices(officesFromServer);
  };

  const resetDevices = async () => {
    if (window.confirm("Are you sure want to reset user devices ?")) {
      await resetMobileDevice(userInfo._id);
      setUserInfo({ ...userInfo, devices: [] });
    }
  };

  useEffect(() => {
    if (selectedUser !== "") {
      getOffices();
    }
  }, [selectedUser, refresh]);

  useEffect(() => {
    if (selectedUser !== "") {
      getUser();
    }
  }, [selectedUser, resetModal, deleteUserModal]);

  return (
    <div className="view-user-container">
      <div className="view-user-half-container">
        <p className="view-user-title">Select a User</p>
        <div style={{ width: "65%" }}>
          <UserDropdown handleChange={handleSelectUser} />
        </div>
        {userInfo.email ? (
          userInfo.active ? (
            <div className="view-user-card-content-container">
              <p className="view-user-title">Assigned Offices</p>
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <td className="table-item">Office</td>
                      <td className="table-item">Assignment</td>
                    </tr>
                  </thead>
                  {assignedOffices.map((office) => (
                    <UserOffice
                      key={office.officeId}
                      officeId={office.officeId}
                      userId={selectedUser}
                      officeSysName={office.officeSysName}
                      reload={handleRefresh}
                      dbService={office.dbService}
                      pbService={office.pbService}
                      fsService={office.fsService}
                    />
                  ))}
                </table>{" "}
              </div>
            </div>
          ) : (
            <div className="view-user-card-content-container">
              <p className="view-user-title">This user was deleted</p>
              <div>
                <button
                  className="view-user-btn"
                  onClick={() => setReactivateUserModal(true)}
                >
                  Reactivate this user
                </button>
                {reactivateUserModal && (
                  <ReactivateUserModal
                    setReactivateUserModal={setReactivateUserModal}
                    userId={userInfo._id}
                  />
                )}
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      {userInfo.email ? (
        userInfo.active ? (
          <div className="view-user-half-container">
            <div className="view-user-card-content-container">
              <p className="view-user-title">User Info</p>
              <div className="view-user-table-container">
                <table className="view-user-table">
                  <tbody>
                    <tr>
                      <td className="view-user-table-heading">Email</td>
                      <td className="view-user-table-item">{userInfo.email}</td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">First Name</td>
                      <td className="view-user-table-item">
                        {userInfo.firstName}
                      </td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">Last Name</td>
                      <td className="view-user-table-item">
                        {userInfo.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">Verifed User</td>
                      <td className="view-user-table-item">
                        {userInfo.isVerified ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">Access Level</td>
                      <td className="view-user-table-item">
                        {" "}
                        {userInfo.access === 1
                          ? "SC"
                          : userInfo.access === 2
                          ? "TL"
                          : userInfo.access === 3
                          ? "IT"
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">Active User</td>
                      <td className="view-user-table-item">
                        {userInfo.active ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td className="view-user-table-heading">Phone Number</td>
                      <td className="view-user-table-item">
                        {userInfo.phoneNumber}
                      </td>
                    </tr>

                    <tr>
                      <td className="view-user-table-heading">
                        Device Information
                      </td>
                      <td className="view-user-table-item">
                        {userInfo.device && !userInfo.devices ? (
                          <ul className="view-user-ul">
                            <li>Brand: {userInfo.device.brand}</li>
                            <li>Model: {userInfo.device.model}</li>
                            <li>OS: {userInfo.device.os} </li>
                            <li>Carrier: {userInfo.device.carrier}</li>
                            <li>App Version: {userInfo.device.appVer}</li>
                          </ul>
                        ) : null}
                        {userInfo.devices
                          ? userInfo.devices.map((x) => (
                              <ul className="view-user-ul">
                                <li>Brand: {x.brand}</li>
                                <li>Model: {x.model}</li>
                                <li>OS: {x.os} </li>
                                <li>Carrier: {x.carrier}</li>
                                <li>App Version: {x.appVer}</li>
                              </ul>
                            ))
                          : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="view-user-btns-container">
                <button
                  className="view-user-btn"
                  onClick={() => resetDevices()}
                >
                  Reset Devices
                </button>
                <button
                  className="view-user-btn"
                  onClick={() =>
                    navigate(
                      `/editUser/${selectedUser}/${userInfo.email}/${userInfo.firstName}/${userInfo.lastName}`
                    )
                  }
                >
                  Edit User
                </button>
                <button
                  className="view-user-btn"
                  onClick={() =>
                    navigate(
                      `/editUserNumber/${selectedUser}/${userInfo.email}`
                    )
                  }
                >
                  Edit Phone Number
                </button>
                {userInfo.device && (
                  <button
                    className="view-user-btn"
                    onClick={() => setResetModal(true)}
                  >
                    Reset User Device
                  </button>
                )}
                {resetModal && (
                  <ResetDeviceModal
                    setResetModal={setResetModal}
                    userId={selectedUser}
                  />
                )}
                <button
                  className="view-user-btn"
                  onClick={() => setDeleteUserModal(true)}
                >
                  Delete User
                </button>

                {deleteUserModal && (
                  <DeleteUserModal
                    setDeleteUserModal={setDeleteUserModal}
                    userId={userInfo._id}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ViewUserInfo;
