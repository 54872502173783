import serverUrl from "../../constants/serverUrl";

//Takes in a valid authToken as an argument and calls the /user/startVerify route on the server
//if the result from the server is 0 (success) then it returns true else it returns false
const startVerify = async (token) => {
  const res = await fetch(`${serverUrl}/user/startVerify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await res.json();

  if (data.result === 0) {
    return true;
  } else {
    return false;
  }
};

export const login = async (email, password) => {
  try {
    const res = await fetch(`${serverUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        type: "web",
      }),
    });

    const data = await res.json();

    if (data.result === 0) {
      const verify = await startVerify(data.token);

      if (verify) return data;
      else return "failure";
    } else return data;
  } catch (err) {
    console.log(err);
    return { result: 1, message: "catch error" };
  }
};

//Takes in a code (string) and a user object as arguments. Calls the /user/verifyCheck route on the server
//if the server responds with a 0 (success) result then the user data from the user object argument is stored
//in async storage and the function returns true. Else the function returns false
export const finishVerify = async (code, userInfo) => {
  const res = await fetch(`${serverUrl}/user/verifyCheck`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
    body: JSON.stringify({
      code,
    }),
  });

  const data = await res.json();

  if (data.result === 0) {
    localStorage.setItem("authToken", JSON.stringify(userInfo.token));
    localStorage.setItem("email", JSON.stringify(userInfo.user.email));
    localStorage.setItem("refreshToken", JSON.stringify(userInfo.refreshToken));
    localStorage.setItem("userId", JSON.stringify(userInfo.user._id));
    localStorage.setItem("access", JSON.stringify(userInfo.user.access));
    return true;
  } else {
    return false;
  }
};
