import React from "react";
import HeaderLink from "./HeaderLink";
import { headerLinks } from "../../constants/links";

const LinksContainer = ({ isLogin }) => {
  return (
    <>
      {isLogin && (
        <div className="header-links-container">
          {headerLinks.map((link) => (
            <HeaderLink key={link.title} title={link.title} to={link.to} />
          ))}
        </div>
      )}
    </>
  );
};

export default LinksContainer;
