import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { updateUser } from "../../functions/user/updateUsers";
import ResultBox from "../utility/ResultBox";
import Select from "react-select";
import { useNavigate } from "react-router";

const EditUser = () => {
  const { userId, userEmail, userFirstName, userLastName } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [confirmActive, setConfirmActive] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState(null);
  const [selectedActive, setSelectedActive] = useState(null);
  const [trueFalseOptions] = useState([
    {
      value: true,
      label: "True",
    },
    {
      value: false,
      label: "False",
    },
  ]);
  const [options] = useState([
    {
      value: 1,
      label: "SC",
    },
    {
      value: 2,
      label: "TL",
    },
    {
      value: 3,
      label: "IT",
    },
  ]);

  const handleUpdate = async () => {
    setResultMessage("Updating...");
    const res = await updateUser(
      userId,
      firstName,
      lastName,
      email,
      selectedAccess ? selectedAccess.value : null,
      selectedActive ? selectedActive.value : null
    );

    if (res) {
      setResultMessage("Success: User has been updated.");
      setSuccess(true);
      setShowResult(true);
    } else {
      setResultMessage("Error: Failed to update user.");
      setSuccess(false);
      setShowResult(true);
    }

    setTimeout(() => {
      navigate("/viewUserInfo");
    }, 2000);
  };

  const showConfirm = () => {
    setConfirmActive(true);
  };

  const reset = () => {
    setResultMessage("");
    setShowResult(false);
    setSuccess(false);
    setConfirmActive(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "65px",
        marginTop: "10vh",
      }}
    >
      <div className="form-container">
        <h4 className="form-title">Update {userEmail}</h4>
        <p className="instructions">
          Change the fields you want to update and then select update.
        </p>
        <p className="form-item">First Name</p>
        <input
          className="form-input"
          onChange={(e) => setFirstName(e.target.value)}
          defaultValue={userFirstName}
        />
        <p className="form-item">Last Name</p>
        <input
          className="form-input"
          onChange={(e) => setLastName(e.target.value)}
          defaultValue={userLastName}
        />
        <p className="form-item">eAssist.me Email</p>
        <input
          className="form-input"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          defaultValue={userEmail}
        />
        <br />
        <p className="instructions">
          These dropdown fields will remain the same if nothing is selected.{" "}
        </p>
        <p className="form-item">Access Level</p>
        <Select
          className="form-input"
          options={options}
          onChange={setSelectedAccess}
          placeholder={"Select access level"}
        />
        <p className="form-item">Active User</p>
        <Select
          className="form-input"
          options={trueFalseOptions}
          onChange={setSelectedActive}
          placeholder={"True/False"}
        />

        <br />
        {confirmActive ? (
          <>
            <p>Are you sure you want to update?</p>
            <div className="form-btn-container">
              <button className="form-btn" onClick={() => handleUpdate()}>
                Update
              </button>
              <button className="form-btn" onClick={reset}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <button className="form-btn" onClick={showConfirm}>
            Update
          </button>
        )}

        {showResult && (
          <>
            <ResultBox message={resultMessage} success={success} />

            <button className="form-btn" onClick={reset}>
              Reset
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditUser;
